import { makeStyles, Theme } from "@material-ui/core";

export const useAppStyles = makeStyles(
  (theme: Theme) => ({
    loader: {
      display: "flex",
      justifyContent: "center"
    }
  }),
  { name: "AppBootstrap" }
);

import { createSelector } from "@reduxjs/toolkit";
import {
  OnlineAccountWrapperApplicationState,
  OnlineAccountWrapperStateKey
} from "@pie/online-account-externals";

export const selectIsLeaveModalOpen = createSelector(
  (state: OnlineAccountWrapperApplicationState) =>
    state[OnlineAccountWrapperStateKey.LEAVE_MODAL],
  leaveModal => leaveModal.isOpen
);

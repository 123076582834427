import {
  OnlineAccountWrapperStateKey,
  OnlineAccountWrapperApplicationState,
  LoadingState
} from "@pie/online-account-externals";
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

export const initialState: LoadingState = false;

export type LoadingAction = PayloadAction<boolean>;

const loadingState = createSlice({
  name: OnlineAccountWrapperStateKey.LOADING,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return action.payload;
    }
  }
});

export const selectLoadingState = createSelector(
  (state: OnlineAccountWrapperApplicationState) =>
    state[OnlineAccountWrapperStateKey.LOADING],
  state => state
);

export const loadingActions = loadingState.actions;

export default loadingState.reducer;

import { ApiRoute, fetchOptions } from "@api/constants";

export const deleteAuditDocumentApi = (
  idToken: string,
  policyNumber: string,
  documentName: string
): Promise<Response> =>
  fetch(
    `${ApiRoute.AUDIT_INTAKE_BASE_ROUTE}/${policyNumber}/document/${documentName}`,
    {
      method: "DELETE",
      credentials: fetchOptions.credentials,
      headers: { ...fetchOptions.headers, Authorization: `Bearer ${idToken}` }
    }
  );

import { makeStyles, Theme } from "@material-ui/core";

export const useErrorBoundaryStyles = makeStyles((theme: Theme) => ({
  content: {
    textAlign: "center"
  },
  gridContainer: {
    flex: "1 1 100%"
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1)
  },
  callNowCard: {
    margin: theme.spacing(6, 0, 2)
  }
}));

import { OnlineAccountWrapperStateKey } from "@pie/online-account-externals";
import { analyticsActions, AnalyticsUser } from "@pie/components";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {} as AnalyticsUser;

const analyticsUserSlice = createSlice({
  name: OnlineAccountWrapperStateKey.ANALYTICS_USER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(analyticsActions.init, (_state, action) => {
      return action.payload.analyticsUser;
    });
  }
});

export default analyticsUserSlice.reducer;

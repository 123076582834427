import { createMuiTheme } from "@material-ui/core";
import { pieLightTheme } from "@pie/components";
import merge from "lodash/merge";

const onlineAccountWrapperUIOverrideTheme = merge(pieLightTheme, {
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: "none"
      }
    }
  }
});
export const theme = createMuiTheme(onlineAccountWrapperUIOverrideTheme);

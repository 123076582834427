import { SnackOptions } from "@pie/online-account-externals";

// An hour in milliseconds
export const SESSION_INACTIVITY_TIMEOUT = 60 * 60 * 1000;

export enum ExternalLinks {
  FAQ_LINK = "https://pieinsurance.com/resources/workers-comp-faq/",
  WORKERS_COMP_LINK = "https://pieinsurance.com/workers-compensation-insurance/",
  AUDIT_LINK = "https://pieinsurance.com/resources/premium-audit/",
  CONTACT_LINK = "https://pieinsurance.com/contact/",
  WORKPLACE_INJURY_LINK = "https://pieinsurance.com/customer-support/report-a-workplace-injury/",
  TERMS_OF_USE_LINK = "https://pieinsurance.com/terms-of-use/",
  TERMS_AND_CONDITIONS_LINK = "https://pieinsurance.com/electronic-delivery-terms/",
  PARTNER_PORTAL_SIGN_IN = "https://partner.pieinsurance.com/sign-in",
  HOW_TO_RESPOND_TO_WORKPLACE_INJURY = "https://cdn.builder.io/o/assets%2Fb3f4fdb80a8d4c9884b91e16fa0d2aab%2F5d96d489616a48939f71d9c5ab45466a?alt=media&token=00d9b0ab-5191-4de7-96fd-e02ac58a66e4"
}

export enum TabOption {
  HOME = "Home",
  POLICY = "Policy",
  PAYMENTS = "Payments",
  DOCUMENTS = "Documents"
}

export const CustomerCarePhoneNumber = "855-880-0204";
export const ReportClaimPhoneNumber = "844-581-0828";
export const CustomerCareEmail = "service@pieinsurance.com";

export enum FieldName {
  EMAIL = "email",
  POLICY_NUMBER = "policyNumber",
  ZIP_CODE = "zipCode",
  PASSWORD = "password",
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  ROUTING_NUMBER = "routingNumber",
  CONFIRM_ROUTING_NUMBER = "confirmRoutingNumber",
  ACCOUNT_NUMBER = "accountNumber",
  CONFIRM_ACCOUNT_NUMBER = "confirmAccountNumber",
  CARD_NUMBER = "cardNumber",
  CARD_EXPIRY = "cardExpiry",
  CARD_CVC = "cardCvc",
  ACCOUNT_TYPE = "accountType",
  BILLING_TYPE = "billingType",
  ROLE = "role",
  TERMS_AND_CONDITIONS = "termsAndConditions"
}

export const SnackbarStatus = {
  emailSendSuccess: {
    message: "Email successfully sent.",
    options: {
      variant: "success"
    } as SnackOptions
  },
  accountCreatedSuccess: {
    message: "Account successfully created.",
    options: {
      variant: "success"
    } as SnackOptions
  },
  accountAlreadyExistError: {
    message: "Account already exists.",
    options: {
      variant: "error"
    } as SnackOptions
  },
  error: {
    message: "Something went wrong. Please try again later!",
    options: {
      variant: "error"
    } as SnackOptions
  }
};

export const InternalPaymentSuccessMessage =
  "Success. Your payment method has been updated. Your next payment will be billed to this payment method.";

export const appName = "onlineaccountwrapperui";

export const temporaryServiceOutageMessage =
  "Some of our services are temporarily unavailable. For help, please contact us at 855-880-0204.";

import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import {
  ReportClaimPhoneNumber,
  ExternalLinks
} from "../../../utils/constants";
import { useContactBlock, useFooterStyles } from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserInjured } from "@fortawesome/pro-solid-svg-icons/faUserInjured";

export interface ReportAccidentProps {
  noIcon?: boolean;
}

export const ReportAccident: React.FC<ReportAccidentProps> = ({ noIcon }) => {
  const { blockStyle } = useContactBlock();
  const { iconStyle } = useFooterStyles();
  const { WORKPLACE_INJURY_LINK } = ExternalLinks;
  return (
    <Grid container>
      {noIcon ? null : (
        <Grid item>
          <FontAwesomeIcon
            icon={faUserInjured}
            size="lg"
            className={iconStyle}
          />
        </Grid>
      )}
      <Grid item className={blockStyle}>
        <Typography variant="body2" gutterBottom>
          Report an accident
        </Typography>

        <Typography display="block" variant="caption">
          Available 24/7
        </Typography>
        <Typography variant="caption">
          <Link href={`tel:${ReportClaimPhoneNumber}`}>
            {ReportClaimPhoneNumber}
          </Link>
        </Typography>
        <Typography display="block" variant="caption">
          <Link
            href={WORKPLACE_INJURY_LINK}
            target="_blank"
            rel="noopener"
            underline="always"
          >
            Learn more
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

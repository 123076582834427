import React, { FC, useEffect, useState } from "react";
import { Location } from "history";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router-dom";
import { push } from "connected-react-router";

import { LeavePageModal } from "@components/LeavePageModal";
import {
  AdditionalAuditAnalyticEvents,
  auditIntakeMap
} from "@components/AuditIntake/types";
import { analyticsAction } from "@pie/online-account-externals";

export const LeavePagePrompt: FC = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [nextLocation, setNextLocation] = useState<string | null>(null);
  const [allowToNavigate, setAllowToNavigate] = useState(false);

  const handleNavigation = (nextLocation: Location): boolean => {
    if (allowToNavigate) {
      return true;
    }

    setShowModal(true);
    setNextLocation(nextLocation.pathname);
    return false;
  };

  const handleClose = () => setShowModal(false);

  const handleCancel = () => {
    const actionName = auditIntakeMap.get(
      AdditionalAuditAnalyticEvents.HANDLE_LEAVE_MODAL_CANCEL
    );
    actionName &&
      dispatch(
        analyticsAction({
          actionName: actionName,
          type: "Click"
        })
      );
    setShowModal(false);
  };

  const handleConfirm = () => {
    const actionName = auditIntakeMap.get(
      AdditionalAuditAnalyticEvents.HANDLE_LEAVE_MODAL_CONFIRM
    );
    actionName &&
      dispatch(
        analyticsAction({
          actionName: actionName,
          type: "Click"
        })
      );
    handleClose();
    setAllowToNavigate(true);
  };

  useEffect(() => {
    if (allowToNavigate && nextLocation) {
      dispatch(push(nextLocation));
    }
  }, [allowToNavigate, nextLocation]);

  return (
    <>
      <Prompt when={true} message={handleNavigation} />
      {showModal && (
        <LeavePageModal
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          handleClose={handleClose}
          showModal
        />
      )}
    </>
  );
};

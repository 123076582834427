import { makeStyles, Theme } from "@material-ui/core";

export const useLinkStyles = makeStyles((theme: Theme) => ({
  icon: {
    padding: "0.375rem",
    width: "1.125rem"
  },
  labelStyle: {
    lineHeight: 1.1,
    marginLeft: "0.5rem"
  },
  componentStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontWeight: theme.typography.fontWeightMedium
  }
}));

import { configureStore } from "@reduxjs/toolkit";
import type { EnhancedStore, Reducer, Unsubscribe } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory, History } from "history";
import { noop } from "lodash";
// Absolutely no clue why this has to be to '@redux-saga/core' set for a test to pass
import createSagaMiddleware from "@redux-saga/core";
import type { Saga, Task } from "redux-saga";
import { createAnalyticsMiddleware } from "./analytics/middleware";
import { createRootReducerWithErrorHandling } from "./root/rootReducer";
import type {
  AnyEventMap,
  OnlineAccountWrapperApplicationState
} from "@pie/online-account-externals";
import rootSaga from "./root/rootSaga";
import { resetContext } from "./snackbar";

export interface InjectionFunctions {
  injectReducer(key: string, reducer: Reducer): Promise<string>;

  injectSaga(key: string, saga: Saga): Promise<string>;

  injectAnalyticsEvents(newEvents: AnyEventMap): Promise<string>;

  subscribe(listener: () => void): Unsubscribe;

  getState<S = OnlineAccountWrapperApplicationState>(): S;
}

export type AppStoreType = EnhancedStore &
  InjectionFunctions & {
    asyncReducers: { [key: string]: Reducer };
    asyncSagas: Map<string, Task>;
  };

const sagaMiddleware = createSagaMiddleware();
const analyticsMiddleware = createAnalyticsMiddleware();

const createReduxStore = (
  history: History,
  errorCallback: (exception: any) => void
) => {
  // Generate initial reducer with no AsyncReducers
  const reducer = createRootReducerWithErrorHandling(
    history,
    errorCallback,
    {}
  );

  const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({ thunk: false }),
      sagaMiddleware,
      routerMiddleware(history),
      analyticsMiddleware.middleware
    ],
    devTools: process.env.NODE_ENV !== "production"
  }) as AppStoreType;

  // Setup store for dynamic sub app events
  store.injectAnalyticsEvents = (newEvents: AnyEventMap): Promise<string> => {
    try {
      analyticsMiddleware.addDynamicEvents(newEvents);
      return Promise.resolve("injectAnalyticsEvents success");
    } catch (ex) {
      return Promise.reject(ex);
    }
  };

  // Setup store for loading external reducers
  store.asyncReducers = {};
  store.injectReducer = (key: string, asyncReducer: any): Promise<string> => {
    try {
      resetContext();
      store.asyncReducers[key] = asyncReducer;
      store.replaceReducer(
        createRootReducerWithErrorHandling(
          history,
          errorCallback,
          store.asyncReducers
        )
      );

      return Promise.resolve("injectReducer success");
    } catch (ex) {
      return Promise.reject(ex);
    }
  };

  // Setup store for loading external sagas
  store.asyncSagas = new Map();
  store.injectSaga = (key: string, asyncSaga: any): Promise<string> => {
    try {
      const existingTask = store.asyncSagas.get(key);
      // Don't replace unless this in dev/hot.module
      if (!import.meta.hot && existingTask)
        return Promise.resolve("injectSaga success HMR");
      if (import.meta.hot && existingTask && existingTask.isRunning()) {
        existingTask.cancel();
        while (!existingTask.isCancelled()) {
          noop();
        }
      }

      // Start the saga and save task so we can cancel if needed in the future
      const task = sagaMiddleware.run(asyncSaga);
      task.toPromise().catch(errorCallback);
      store.asyncSagas.set(key, task);

      return Promise.resolve("injectSaga success");
    } catch (ex) {
      return Promise.reject(ex);
    }
  };

  let sagaTask = sagaMiddleware.run(rootSaga);
  sagaTask.toPromise().catch(errorCallback);

  if (process.env.NODE_ENV !== "production" && import.meta.hot) {
    import.meta.hot.accept("./root/rootReducer", async () => {
      const newReducers = (
        await import("./root/rootReducer")
      ).createRootReducerWithErrorHandling(
        history,
        errorCallback,
        store.asyncReducers
      );
      store.replaceReducer(newReducers);
    });
    import.meta.hot.accept("./root/rootSaga", async () => {
      const newSagas = await import("./root/rootSaga");
      sagaTask.cancel();
      while (!sagaTask.isCancelled()) {
        noop();
      }
      sagaTask = sagaMiddleware.run(newSagas.default);
      sagaTask.toPromise().catch(errorCallback);
    });
  }

  return store;
};

export const history = createBrowserHistory();
export const createStore = (errorCallback: (exception: any) => void) =>
  createReduxStore(history, errorCallback);

import React from "react";
import { CustomerCarePhoneNumber } from "@utils/constants";
import { Link } from "@material-ui/core";

export const EmailFailed: React.FC = () => (
  <span>
    Email cannot be sent. Please contact us at{" "}
    <Link href={`tel:${CustomerCarePhoneNumber}`}>
      {CustomerCarePhoneNumber}
    </Link>
  </span>
);

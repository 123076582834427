import React from "react";
import { Paper, Fab, Typography, Link, Grid } from "@material-ui/core";

import { callNowStyles } from "./styles";
import { CustomerCarePhoneNumber } from "../../utils/constants";
import { useCommonStyle } from "../commonStyles";
import { faPhoneAlt } from "@fortawesome/pro-solid-svg-icons/faPhoneAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const needHelpText = "Need help?";
export const pieHoursOfOperation = "M-F 9am-9pm ET";

export interface CallNowProps {
  hoursOfOperation?: string;
  contactNumber?: string;
  contactEmail?: string;
  messageText?: string;
}

export const CallNow: React.FC<CallNowProps> = ({
  hoursOfOperation = pieHoursOfOperation,
  contactNumber = CustomerCarePhoneNumber,
  contactEmail,
  messageText = needHelpText
}) => {
  const { buttonStyle, iconStyle, paperStyle, contentStyle } = callNowStyles();
  const { fontWeightMedium } = useCommonStyle();
  return (
    <>
      <Paper elevation={3} className={paperStyle}>
        <Grid container direction="row" alignItems="center" wrap="nowrap">
          <Grid item className={contentStyle}>
            <Fab
              disableRipple
              size="small"
              className={buttonStyle}
              id="contact-button"
            >
              <FontAwesomeIcon icon={faPhoneAlt} className={iconStyle} />
            </Fab>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={fontWeightMedium}>
              {messageText}
            </Typography>
            <Link
              id="contact-phone"
              href={`tel:${contactNumber}`}
              variant="body1"
              underline="none"
            >
              {contactNumber}
            </Link>
          </Grid>
        </Grid>
      </Paper>
      {contactEmail ? (
        <Link
          id="contact-email"
          href={`mailto:${contactEmail}`}
          variant="body1"
          align="center"
        >
          {contactEmail}
        </Link>
      ) : null}
      <Typography
        variant="body2"
        align="center"
        color="inherit"
        className={fontWeightMedium}
      >
        {hoursOfOperation}
      </Typography>
    </>
  );
};

import {
  AnalyticsActionCreator,
  AnalyticsEvent,
  OnlineAccountWrapperApplicationState,
  convertToAnalyticsEvent,
  analyticsGenericActionName
} from "@pie/online-account-externals";
import { PageNames } from "@routes";
import { getActionName } from "../utils";
import {
  AnalyticsActionTypes,
  LocationChangeActionPayload
} from "@pie/components";
import { PayloadAction } from "@reduxjs/toolkit";

export const genericEventMap = {
  [analyticsGenericActionName]: (
    action: ReturnType<AnalyticsActionCreator>,
    state: OnlineAccountWrapperApplicationState
  ): AnalyticsEvent => {
    const { actionName, type, options, ...rest } = action.payload;
    return convertToAnalyticsEvent(
      state,
      {
        actionName: getActionName(actionName, type),
        ...rest
      },
      options
    );
  },
  [AnalyticsActionTypes.LOCATION_CHANGE]: (
    action: PayloadAction<LocationChangeActionPayload>,
    state: OnlineAccountWrapperApplicationState
  ): AnalyticsEvent | undefined => {
    const { pathname } = action.payload.location;
    if (pathname in PageNames)
      return convertToAnalyticsEvent(
        { ...state, analyticsUser: { ...action.payload.analyticsUser } },
        {
          actionName: `${
            PageNames[pathname as keyof typeof PageNames]
          } Page View`
        }
      );
  }
};

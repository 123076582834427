import {
  Audit,
  AuditAnalyticsEvents,
  AuditStatus,
  WrapperScreen
} from "@pie-insurance/shared-screens-audit/src";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import {
  analyticsAction,
  AuthRoutes,
  OnlineAccountWrapperSettings,
  selectAuthUserIdToken
} from "@pie/online-account-externals";
import { FC, useEffect } from "react";
import type { SaveAuditPayload } from "@stores/auditIntake";
import { auditIntakeActions, selectAuditState } from "@stores/auditIntake";
import { LeavePagePrompt } from "@components/LeavePagePrompt";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  finalizeAuditIntakeApi,
  saveAuditIntakeApi,
  startAuditIntakeApi
} from "@api/auditIntakeApi/auditIntakeApi";
import { ApiRoute } from "@api/constants";
import { deleteAuditDocumentApi } from "@api/auditIntakeApi/auditIntakeDocumentApi";
import {
  AdditionalAuditAnalyticEvents,
  auditIntakeMap
} from "@components/AuditIntake/types";
import { useSettings } from "@pie/components";
import { initMouseFlowFeedbackSurvey } from "@utils/helpers";

export const AuditIntake: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { policyNumber } = queryString.parse(location.search) || "";
  const idToken = useSelector(selectAuthUserIdToken);
  const stringifiedPolicyNumber = policyNumber?.toString();
  const { audit } = useSelector(selectAuditState);
  const { mouseFlowConfiguration } =
    useSettings<OnlineAccountWrapperSettings>();

  useEffect(() => {
    if (stringifiedPolicyNumber) {
      dispatch(auditIntakeActions.getAuditIntake(stringifiedPolicyNumber));
    }
  }, [dispatch, location.pathname, stringifiedPolicyNumber]);

  const containerAlignment =
    audit?.status === AuditStatus.COMPLETED ? "center" : "flex-start";

  const handleBackToAccount = () => {
    switch (audit?.status) {
      case AuditStatus.NOT_STARTED: {
        const actionName = auditIntakeMap.get(
          AdditionalAuditAnalyticEvents.BACK_TO_ACCOUNT_CLICKED_ON_WELCOME_SCREEN
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditStatus.IN_PROGRESS: {
        const actionName = auditIntakeMap.get(
          AdditionalAuditAnalyticEvents.BACK_TO_ACCOUNT_CLICKED_ON_FORM_SCREEN
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditStatus.COMPLETED: {
        const actionName = auditIntakeMap.get(
          AdditionalAuditAnalyticEvents.BACK_TO_ACCOUNT_CLICKED_ON_CONFIRMATION_SCREEN
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
    }
    dispatch(push(AuthRoutes.HOME));
  };

  const handleUpdateAuditState = () => {
    if (stringifiedPolicyNumber) {
      dispatch(auditIntakeActions.getAuditIntake(stringifiedPolicyNumber));
    }
  };

  const handleSave = async (formData: Partial<Audit>) => {
    if (!stringifiedPolicyNumber || !idToken) {
      return;
    }
    const payload: SaveAuditPayload = {
      policyNumber: stringifiedPolicyNumber,
      audit: formData
    };
    return saveAuditIntakeApi(idToken, payload);
  };

  const handleStartAudit = () => {
    if (!stringifiedPolicyNumber || !idToken) {
      return;
    }
    return startAuditIntakeApi(idToken, stringifiedPolicyNumber);
  };

  const handleDeleteDocument = (documentName: string) => {
    if (!stringifiedPolicyNumber || !idToken) {
      return;
    }
    return deleteAuditDocumentApi(
      idToken,
      stringifiedPolicyNumber,
      documentName
    );
  };

  const handleFinalizeAudit = () => {
    if (!stringifiedPolicyNumber || !idToken) {
      return;
    }
    return finalizeAuditIntakeApi(idToken, stringifiedPolicyNumber);
  };

  const handleAnalyticsEvent = (eventName: AuditAnalyticsEvents) => {
    switch (eventName) {
      case AuditAnalyticsEvents.CONTINUE_BUTTON_CLICKED: {
        const actionName = auditIntakeMap.get(
          AuditAnalyticsEvents.CONTINUE_BUTTON_CLICKED
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditAnalyticsEvents.SUBMIT_BUTTON_CLICKED: {
        const actionName = auditIntakeMap.get(
          AuditAnalyticsEvents.SUBMIT_BUTTON_CLICKED
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditAnalyticsEvents.BUSINESS_INFORMATION_SECTION_SAVED: {
        const actionName = auditIntakeMap.get(
          AuditAnalyticsEvents.BUSINESS_INFORMATION_SECTION_SAVED
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditAnalyticsEvents.OWNERS_AND_OFFICERS_SECTION_SAVED: {
        const actionName = auditIntakeMap.get(
          AuditAnalyticsEvents.OWNERS_AND_OFFICERS_SECTION_SAVED
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditAnalyticsEvents.EMPLOYEE_INFORMATION_SECTION_SAVED: {
        const actionName = auditIntakeMap.get(
          AuditAnalyticsEvents.EMPLOYEE_INFORMATION_SECTION_SAVED
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditAnalyticsEvents.CONTRACTORS_SECTION_SAVED: {
        const actionName = auditIntakeMap.get(
          AuditAnalyticsEvents.CONTRACTORS_SECTION_SAVED
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditAnalyticsEvents.PAYROLL_VERIFICATION_SECTION_SAVED: {
        const actionName = auditIntakeMap.get(
          AuditAnalyticsEvents.PAYROLL_VERIFICATION_SECTION_SAVED
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
      case AuditAnalyticsEvents.ADDITIONAL_DETAILS_SECTION_SAVED: {
        const actionName = auditIntakeMap.get(
          AuditAnalyticsEvents.ADDITIONAL_DETAILS_SECTION_SAVED
        );
        actionName &&
          dispatch(
            analyticsAction({
              actionName: actionName,
              type: "Click"
            })
          );
        break;
      }
    }
  };

  const handleInitFeedbackSurvey = (): void => {
    initMouseFlowFeedbackSurvey(
      mouseFlowConfiguration?.auditIntakeFeedbackSurveyId
    );
  };

  const deleteDocumentUrl = (policyNumber: string) =>
    `${ApiRoute.AUDIT_INTAKE_BASE_ROUTE}/${policyNumber}/document`;

  const saveDocumentUrl = (policyNumber: string) =>
    `${ApiRoute.AUDIT_INTAKE_BASE_ROUTE}/${policyNumber}/document`;

  return audit ? (
    <Grid
      container
      justify="center"
      alignItems={containerAlignment}
      data-testid="auditScreen"
    >
      <Grid item xs={12} md={8}>
        <WrapperScreen
          audit={audit}
          handleContinue={handleStartAudit}
          handleSubmit={handleFinalizeAudit}
          handleBackToAccount={handleBackToAccount}
          handleSave={handleSave}
          handleUpdateOnlineAccountState={handleUpdateAuditState}
          deleteDocumentUrl={deleteDocumentUrl(stringifiedPolicyNumber)}
          saveDocumentUrl={saveDocumentUrl(stringifiedPolicyNumber)}
          authToken={idToken}
          handleDeleteDocument={handleDeleteDocument}
          handleAnalyticsEvent={handleAnalyticsEvent}
          handleInitFeedbackSurvey={handleInitFeedbackSurvey}
        />
      </Grid>
      {/** Move the leave prompt to the form screen once this app is migrated to the FE repo */}
      {audit.status === AuditStatus.IN_PROGRESS && <LeavePagePrompt />}
    </Grid>
  ) : null;
};

import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { call, selectAuthUserIdToken } from "@pie/online-account-externals";
import { getAuditIntakeApi } from "@api/auditIntakeApi/auditIntakeApi";
import { auditIntakeActions } from "@stores/auditIntake";
import { PayloadAction } from "@reduxjs/toolkit";

export function* getAuditIntakeSaga(
  action: PayloadAction<string>
): SagaIterator {
  try {
    const idToken = yield select(selectAuthUserIdToken);
    const response = yield call(getAuditIntakeApi, idToken, action.payload);

    if (response.ok) {
      const json = yield response.json();
      return yield put(auditIntakeActions.auditIntakeCallFinished(json));
    }
  } catch (error) {
    console.error(error);
  }
}

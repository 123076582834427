import { clearAuthUser } from "@pie/online-account-externals";
import {
  AuthUserState,
  OnlineAccountWrapperStateKey,
  OnlineAccountWrapperApplicationState
} from "@pie/online-account-externals";
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { LOCATION_CHANGE } from "connected-react-router";
import { AuthUser } from "@pie/components/dist/auth/AuthProvider/AuthContext";

export const initialState: AuthUser = {
  email: "",
  username: "",
  subject: ""
};

export type SetAuthUserAction = PayloadAction<AuthUserState>;

const authUser = createSlice({
  name: OnlineAccountWrapperStateKey.AUTH_USER,
  initialState,
  reducers: {
    setAuthUser: (state, action: SetAuthUserAction): AuthUserState => {
      return { ...state, ...action.payload };
    }
  },
  extraReducers: builder => {
    builder.addCase(clearAuthUser, (): AuthUser => initialState);
    builder.addCase(LOCATION_CHANGE, (state: AuthUser) => {
      return { ...state, hasError: false };
    });
  }
});

export const selectAuthUser = createSelector(
  (state: OnlineAccountWrapperApplicationState) => state.authUser,
  authUser => authUser
);

export const authUserActions = {
  ...authUser.actions,
  clearAuthUser
};

export default authUser.reducer;

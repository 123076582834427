import React from "react";
import { Card } from "@pie/components";
import { Grid } from "@material-ui/core";
import logo from "../../../logo.svg";
import { useAuthStyles } from "../styles";

interface AuthCardWrapperProps {
  minWidth?: number;
  maxWidth?: number;
}

export const AuthCardWrapper: React.FC<AuthCardWrapperProps> = ({
  minWidth,
  maxWidth,
  children
}) => {
  const { image, card } = useAuthStyles({ minWidth, maxWidth });

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <Card className={card} displayStyle="tertiary">
          <Grid container justify="center">
            <Grid item>
              <img className={image} src={logo} alt="Pie Insurance" />
            </Grid>
          </Grid>
          {children}
        </Card>
      </Grid>
    </Grid>
  );
};

import React, { useCallback, useState } from "react";
import { useAuthStyles } from "../styles";
import {
  Alert,
  ControlledTextInput,
  LoadingButton,
  useIsMobile
} from "@pie/components";
import { Grid, Link, Typography } from "@material-ui/core";
import { LinkText } from "..";
import { AuthCardWrapper } from "../AuthCardWrapper";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RegistrationEmailPayload } from "@stores/sagas/sendRegistrationEmail/sendRegistrationEmailSaga";
import { PublicRoutes } from "@pie/online-account-externals";
import { CustomerCarePhoneNumber, FieldName } from "@utils/constants";
import { selectLoadingState } from "../../../store/loadingState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { accountActions, selectAccount } from "../../../store/account";

const { POLICY_NUMBER, ZIP_CODE } = FieldName;
export const sendEmailFormTestId = "send-email-form";
export const policyNumberLabel = "Policy Number";
export const zipCodeLabel = "ZIP Code";
export const loginLabel = "Return to login";

const numbersOnly = (value: string, defaultValue: string) => {
  return isNaN(Number(value)) ? defaultValue : value;
};

export const SendRegistrationEmail: React.FC = () => {
  const {
    form,
    linkText,
    button,
    alertStyle,
    alertIcon,
    alertExample,
    alertExampleList
  } = useAuthStyles();
  const formMethods = useForm();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const loading = useSelector(selectLoadingState);
  const { hasError } = useSelector(selectAccount);

  const [policyNumberValue, setPolicyNumberValue] = useState<string>("");
  const [zipValue, setZipValue] = useState<string>("");

  const handleFormSubmit = (data: RegistrationEmailPayload, e: any): void => {
    e.preventDefault();
    dispatch(accountActions.sendRegistrationEmailAction(data));
  };

  const handlePolicyNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.currentTarget;
      setPolicyNumberValue(value?.toUpperCase());
    },
    []
  );

  const handleZipChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.currentTarget;
      setZipValue(value);
    },
    []
  );

  return (
    <AuthCardWrapper>
      <Typography variant="h2" color="primary">
        Welcome! You're on your way to setting up your Pie account.
      </Typography>
      <Typography paragraph color="textSecondary">
        Please provide policy information to activate your account.
      </Typography>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleFormSubmit as any)}
          className={form}
          data-testid={sendEmailFormTestId}
        >
          <Grid
            container
            justify="space-between"
            alignItems="flex-start"
            spacing={isMobile ? 0 : 2}
          >
            <Grid item xs={12} sm={6}>
              <ControlledTextInput
                className="dd-privacy-allow"
                required
                id={POLICY_NUMBER}
                type="text"
                label={policyNumberLabel}
                name={POLICY_NUMBER}
                value={policyNumberValue}
                onChange={handlePolicyNumberChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ControlledTextInput
                className="dd-privacy-allow"
                id={ZIP_CODE}
                label={zipCodeLabel}
                name={ZIP_CODE}
                value={zipValue}
                onChange={handleZipChange}
                formatters={{
                  input: value => numbersOnly(value, zipValue).slice(0, 5),
                  output: (value): string =>
                    numbersOnly(value as string, zipValue).slice(0, 5)
                }}
              />
            </Grid>
            {hasError && (
              <Grid item xs={12}>
                <Alert type="error" className={alertStyle}>
                  <FontAwesomeIcon
                    icon={faExclamationCircle as IconProp}
                    size="lg"
                    className={alertIcon}
                  />
                  <Typography align="left" className={alertExample}>
                    Please enter the correct policy information and check your
                    policy number format.
                    <br />
                    <b>Example format:</b>
                    <ul className={alertExampleList}>
                      <li>WC100111-00 or WC100111</li>
                      <li>WC SA 101011-000 or WC SA 101011</li>
                    </ul>
                    For help please call us at{" "}
                    <Link href={`tel:${CustomerCarePhoneNumber}`}>
                      {CustomerCarePhoneNumber}
                    </Link>
                    .
                  </Typography>
                </Alert>
              </Grid>
            )}
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Grid item style={{ width: isMobile ? "100%" : "unset" }}>
              <LoadingButton
                loading={loading}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!policyNumberValue || !zipValue}
                className={button}
                fullWidth={isMobile}
              >
                Next
              </LoadingButton>
              <Grid item className={linkText}>
                <LinkText to={PublicRoutes.SIGN_IN}>{loginLabel}</LinkText>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </AuthCardWrapper>
  );
};

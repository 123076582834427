import { fetchOptions, ApiRoute, ApiPath } from "../constants";

export interface InternalBankPaymentSetupPayload {
  ownerData: string;
  policyNumber: string;
  idToken: string;
}

export const internalACHSetupApi = (
  payload: InternalBankPaymentSetupPayload
): Promise<Response> => {
  const { idToken, ownerData, policyNumber } = payload;
  return fetch(`${ApiRoute.INTERNAL_PAYMENT_SETUP_BASE}${ApiPath.ACH_PATH}`, {
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${idToken}`
    },
    method: "POST",
    body: JSON.stringify({ ownerData, policyNumber })
  });
};

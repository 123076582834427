import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface AuthStyleProps {
  minWidth?: number;
  maxWidth?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAuthStyles = ({ minWidth, maxWidth }: AuthStyleProps = {}) =>
  makeStyles((theme: Theme) =>
    createStyles({
      card: {
        textRendering: "geometricPrecision",
        minWidth: theme.spacing(minWidth || 45),
        maxWidth: theme.spacing(maxWidth || 60),
        padding: theme.spacing(6.25, 3.75),
        borderWidth: "1px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        [theme.breakpoints.down("xs")]: {
          // To remove x-scrolling
          maxWidth: "100%",
          minWidth: 0
        }
      },
      button: {
        padding: theme.spacing(1.25, 0),
        minWidth: theme.spacing(22),
        // @ts-ignore
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: "1.1em"
      },
      linkWithoutButton: {
        textAlign: "center",
        margin: theme.spacing(1, 0)
      },
      helpText: {
        margin: `${theme.spacing(3)}px auto`
      },
      titleStyle: {
        margin: theme.spacing(2, 0)
      },
      image: {
        height: "auto",
        width: "220px",
        marginBottom: theme.spacing(2.5)
      },
      linkText: {
        marginTop: theme.spacing(2)
      },
      form: {
        textAlign: "center"
      },
      alertIcon: {
        color: theme.palette.error.main,
        marginRight: "10px"
      },
      alertExample: {
        lineHeight: "1.8"
      },
      alertExampleList: {
        listStyleType: "disc",
        listStylePosition: "inside"
      },
      alertStyle: {
        display: "flex",
        justifyContent: "start",
        marginBottom: theme.spacing(3)
      }
    })
  )();

import { makeStyles, Theme } from "@material-ui/core";

const sidePadding = 8;

export const useFooterStyles = makeStyles(
  (theme: Theme) => ({
    topFooter: {
      padding: theme.spacing(2.5, sidePadding),
      background: theme.palette.background.paper
    },
    bottomFooter: {
      padding: theme.spacing(2.5, 2.5, 2.5, sidePadding),
      background: theme.palette.grey[800],
      "& a": {
        "font-weight": "normal",
        color: theme.palette.primary.contrastText
      },
      "& hr": {
        backgroundColor: theme.palette.primary.contrastText,
        opacity: 0.6
      }
    },
    iconStyle: {
      color: theme.palette.primary.main
    }
  }),
  { name: "Footer" }
);

export const useContactBlock = makeStyles(
  (theme: Theme) => ({
    blockStyle: {
      padding: theme.spacing(0, 1)
    }
  }),
  { name: "FooterContactBlock" }
);

import { makeStyles, Theme } from "@material-ui/core";

export const useContactCardStyles = makeStyles((theme: Theme) => ({
  typography: {
    fontWeight: theme.typography.fontWeightMedium
  },
  link: {
    display: "block",
    marginBottom: theme.spacing(2)
  }
}));

import { SagaIterator } from "redux-saga";
import { PaymentActionType, paymentActions } from "../../paymentSetup";
import {
  ResendPaymentEmailRequestPayload,
  resendPaymentSetupEmailApi
} from "../../../api/resendPaymentSetupEmail";
import { put } from "redux-saga/effects";
import { loadingActions } from "../../loadingState";
import { push } from "connected-react-router";
import { call, PublicRoutes } from "@pie/online-account-externals";

export interface ResendPaymentSetupAction {
  payload: ResendPaymentEmailRequestPayload;
  type: PaymentActionType.RESEND_PAYMENT_SETUP_EMAIL;
}

export function* resendPaymentSetupEmailSaga(
  action: ResendPaymentSetupAction
): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true));
    const res = yield call(resendPaymentSetupEmailApi, action.payload);
    if (res.ok) {
      yield put(paymentActions.resendPaymentSetupEmailSuccess());
      yield put(loadingActions.setLoading(false));
      return yield put(push(PublicRoutes.PAYMENT_EMAIL_SENT));
    } else {
      throw res;
    }
  } catch (error) {
    yield put(paymentActions.resendPaymentSetupEmailFailed());
    return yield put(loadingActions.setLoading(false));
  }
}

import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar, SnackbarKey } from "notistack";
import {
  OnlineAccountWrapperApplicationState,
  Snack,
  removeSnackbar
} from "@pie/online-account-externals";

// This component is from https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example
export const Notifier: React.FC = () => {
  const dispatch = useDispatch();
  const snacks = useSelector<OnlineAccountWrapperApplicationState, Snack[]>(
    store => store.snackbar.snacks || []
  );
  const [displayed, setDisplayed] = useState<SnackbarKey[]>([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = useCallback(
    (id: SnackbarKey) => {
      setDisplayed([...displayed, id]);
    },
    [displayed, setDisplayed]
  );

  const removeDisplayed = useCallback(
    (id: SnackbarKey) => {
      setDisplayed([...displayed.filter(key => id !== key)]);
    },
    [displayed, setDisplayed]
  );

  useEffect(() => {
    snacks.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          options.onClose?.(event, reason, myKey);
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey));
          removeDisplayed(myKey);
        }
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [
    snacks,
    closeSnackbar,
    enqueueSnackbar,
    dispatch,
    displayed,
    storeDisplayed,
    removeDisplayed
  ]);

  return null;
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Grid } from "@material-ui/core";
import { layoutActions, selectLayoutState } from "@stores/layout";
import { useSideMenuStyles } from "./styles";
import { AuthRoutes } from "@pie/online-account-externals";
import { faHomeLgAlt } from "@fortawesome/pro-duotone-svg-icons/faHomeLgAlt";
import { faFileAlt } from "@fortawesome/pro-duotone-svg-icons/faFileAlt";
import { faMoneyBillWave } from "@fortawesome/pro-duotone-svg-icons/faMoneyBillWave";
import { faUserShield } from "@fortawesome/pro-duotone-svg-icons/faUserShield";
import { faSignOut } from "@fortawesome/pro-duotone-svg-icons/faSignOut";
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons/faTimesCircle";
import { CustomerCare } from "@components/Footer/CustomerCare/CustomerCare";
import { ReportAccident } from "@components/Footer/ReportAccident/ReportAccident";
import { MenuItem } from "./MenuItem";

export const menuItems = [
  { icon: faTimesCircle, label: "Close" },
  { icon: faHomeLgAlt, label: "Home", route: AuthRoutes.HOME },
  { icon: faUserShield, label: "Policy", route: AuthRoutes.POLICY },
  { icon: faMoneyBillWave, label: "Payments", route: AuthRoutes.PAYMENTS },
  { icon: faFileAlt, label: "Documents", route: AuthRoutes.DOCUMENTS },
  { icon: faSignOut, label: "Sign Out", signOut: true }
];

export const SideMenu: React.FC = () => {
  const dispatch = useDispatch();
  const styles = useSideMenuStyles();
  const { isSideMenuOpen } = useSelector(selectLayoutState);

  return (
    <Drawer
      anchor="right"
      open={isSideMenuOpen}
      onClose={() => dispatch(layoutActions.toggleSideMenu(false))}
    >
      <Grid container direction="column" className={styles.menuContent}>
        {menuItems.map(item => (
          <MenuItem
            key={item.label}
            icon={item.icon}
            label={item.label}
            route={item.route}
            signOut={item.signOut}
          />
        ))}
        <Grid item className={styles.footer}>
          <CustomerCare noIcon={true} />
        </Grid>
        <Grid item className={styles.footer}>
          <ReportAccident noIcon={true} />
        </Grid>
      </Grid>
    </Drawer>
  );
};

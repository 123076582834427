import { Link, Typography } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import {
  analyticsAction,
  AnalyticsEventBehavior
} from "@pie/online-account-externals";
import { ExternalLinks } from "@utils/constants";
import { useBodyStyles } from "./styles";

export const Body: React.FC = () => {
  const styles = useBodyStyles();
  const dispatch = useDispatch();

  return (
    <div className={styles.container}>
      <Typography>
        By accepting our{" "}
        <Link
          href={ExternalLinks.TERMS_AND_CONDITIONS_LINK}
          target="_blank"
          rel="noopener"
          underline="always"
          onClick={() =>
            dispatch(
              analyticsAction({
                actionName: "Terms and Conditions link from modal",
                type: AnalyticsEventBehavior.CLICK
              })
            )
          }
        >
          terms and conditions
        </Link>{" "}
        you will be able to receive your documents electronically.
      </Typography>

      <Typography>
        If you don't accept the terms, no changes will be made and documents
        will be sent to you via physical mail.
      </Typography>
    </div>
  );
};

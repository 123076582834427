import { lighten, makeStyles, Theme } from "@material-ui/core";

export const useControlledTextInputStyles = makeStyles(
  (theme: Theme) => ({
    inputClass: {
      marginTop: theme.spacing(1)
    }
  }),
  { name: "PieControlledTextInput" }
);

export const useSnackbarStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > div": {
      borderRadius: 50,
      marginBottom: theme.spacing(1),
      padding: theme.spacing(0.5, 3),
      fontSize: theme.typography.pxToRem(17)
    }
  },
  success: {
    borderWidth: 2,
    borderColor: theme.palette.success.main,
    borderStyle: "solid",
    backgroundColor: lighten(theme.palette.success.main, 0.9),
    color: theme.palette.success.main,
    "& svg": {
      fill: theme.palette.success.main
    }
  },
  error: {
    borderWidth: 2,
    borderColor: theme.palette.error.main,
    borderStyle: "solid",
    backgroundColor: lighten(theme.palette.error.main, 0.8),
    color: theme.palette.error.main
  },
  warning: {
    borderWidth: 2,
    borderColor: theme.palette.warning.main,
    borderStyle: "solid",
    backgroundColor: lighten(theme.palette.warning.main, 0.8),
    color: theme.palette.warning.main,
    "& svg": {
      fill: theme.palette.warning.main
    }
  },
  info: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    color: theme.palette.primary.main,
    "& svg": {
      fill: theme.palette.primary.main
    }
  }
}));

export const useCommonStyle = makeStyles(
  (theme: Theme) => ({
    fontWeightRegular: {
      fontWeight: theme.typography.fontWeightRegular
    },
    fontWeightMedium: {
      fontWeight: theme.typography.fontWeightMedium
    },
    fontWeightBold: {
      fontWeight: theme.typography.fontWeightBold
    }
  }),
  {
    name: "CommonStyle"
  }
);

import React, { useCallback, useState } from "react";
import {
  AuthProvider,
  AuthEventCallback,
  AuthEventType,
  AuthEventUiInteractionPayload,
  useSettings,
  Loader
} from "@pie/components";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMount, useMouseFlow } from "@pie/utils";
import {
  AuthRoutes,
  PublicRoutes,
  OnlineAccountWrapperSettings,
  enqueueSnackbar
} from "@pie/online-account-externals";
import { ActivityMonitor } from "../ActivityMonitor/ActivityMonitor";
import App from "../App/App";
import {
  analyticsAuthActions,
  AnalyticsAuthName
} from "@stores/analytics/submaps/auth";
import { useAppStyles } from "./styles";
import { push } from "connected-react-router";
import { isAuthEventFailCode } from "@utils/helpers";
import { authUserActions } from "../../store/authUser";

export const PasswordResetRequiredException = "PasswordResetRequiredException";
export const UserNotFoundException = "UserNotFoundException";
export const NotAuthorizedException = "NotAuthorizedException";

export const AppBootstrap: React.FC = () => {
  const dispatch = useDispatch();
  const settings = useSettings<OnlineAccountWrapperSettings>();
  const { loader } = useAppStyles();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const { authConfiguration, mouseFlowConfiguration } = settings;

  useMount(() => {
    setIsMounted(true);
  });

  const authEventCallback = useCallback<AuthEventCallback>(
    ({ type, payload }) => {
      switch (type) {
        case AuthEventType.LOGIN_SUCCESSFUL: {
          dispatch(
            analyticsAuthActions.authenticated({
              actionName: AnalyticsAuthName.SIGN_IN_SUCCESS,
              properties: { ...payload },
              analyticsActions: ["TrackEvent", "UpdateProfile"]
            })
          );
          dispatch(push(AuthRoutes.HOME));
          break;
        }
        case AuthEventType.LOGIN_FAILED: {
          dispatch(
            analyticsAuthActions.unauthenticated({
              actionName: AnalyticsAuthName.SIGN_IN_FAILED,
              properties: { ...payload }
            })
          );

          if (isAuthEventFailCode(payload, PasswordResetRequiredException)) {
            dispatch(push(PublicRoutes.SECURITY_UPGRADE, payload.user.email));
          } else if (
            isAuthEventFailCode(
              payload,
              NotAuthorizedException,
              UserNotFoundException
            )
          ) {
            dispatch(
              enqueueSnackbar({
                message: payload.error.message,
                options: {
                  variant: "error"
                }
              })
            );
          }
          break;
        }
        case AuthEventType.UI_INTERACTION: {
          const { eventName, properties } =
            payload as AuthEventUiInteractionPayload;
          dispatch(
            analyticsAuthActions.unauthenticated({
              actionName: eventName,
              properties
            })
          );
          break;
        }
      }
    },
    [dispatch]
  );

  useMouseFlow(mouseFlowConfiguration?.trackingId || "");

  const authLogout = () => {
    dispatch(authUserActions.clearAuthUser());
  };

  return (
    <AuthProvider
      getLocation={useLocation}
      userPoolMapping={{}}
      authConfiguration={authConfiguration}
      authEventCallback={authEventCallback}
      logoutCallback={authLogout}
    >
      {!isMounted && <Loader className={loader} size="5em" />}
      {isMounted && <App />}
      <ActivityMonitor />
    </AuthProvider>
  );
};

import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Grid, Typography } from "@material-ui/core";
import { layoutActions } from "@stores/layout";
import { useSideMenuStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useHistory } from "react-router";
import { leaveModalActions } from "@stores/leaveModal";

export interface MenuItemProps {
  icon: IconDefinition;
  label: string;
  route?: string;
  signOut?: boolean;
}

export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  label,
  route,
  signOut
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useSideMenuStyles();

  const handleListItemClick = useCallback(() => {
    if (signOut) {
      dispatch(leaveModalActions.showModal());
    } else if (route) {
      history.push(route);
    }
    dispatch(layoutActions.toggleSideMenu(false));
  }, [dispatch, layoutActions, leaveModalActions, history, route]);

  const isSelected = useMemo(
    () => history.location.pathname === route,
    [history, route]
  );

  const menuItemStyle = useMemo(() => {
    if (signOut) {
      return styles.signOutMenuItem;
    } else if (isSelected) {
      return styles.menuItemSelected;
    } else {
      return styles.menuItem;
    }
  }, [
    isSelected,
    signOut,
    styles.signOutMenuItem,
    styles.menuItemSelected,
    styles.menuItem
  ]);

  return (
    <Grid item className={menuItemStyle}>
      <Button
        className={
          isSelected ? styles.menuItemContentSelected : styles.menuItemContent
        }
        onClick={handleListItemClick}
      >
        <FontAwesomeIcon
          icon={icon as IconProp}
          size="lg"
          className={styles.iconStyle}
        />
        <Typography variant="h5" className={styles.label}>
          {label}
        </Typography>
      </Button>
    </Grid>
  );
};

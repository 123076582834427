// import { ForgotPasswordPayload } from "../../../store/sagas/forgotPasswordSaga/forgotPasswordSaga";

export const onlineAccountForgotPasswordRequest = (
  email: string
): Promise<Response> =>
  fetch("/api/v1/online-account/forgotPasswordRequest", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    },
    body: JSON.stringify({ email })
  });

import {
  clearAuthUser,
  OnlineAccountWrapperApplicationState,
  AccountState,
  OnlineAccountWrapperStateKey
} from "@pie/online-account-externals";
import { createSlice, createSelector, createAction } from "@reduxjs/toolkit";
import { CreatePasswordPayload } from "../sagas/createPassword/createPasswordSaga";
import { RegistrationEmailPayload } from "../sagas/sendRegistrationEmail/sendRegistrationEmailSaga";
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";
import { LOCATION_CHANGE } from "connected-react-router";

export const initialState: AccountState = {
  createPasswordEmail: "",
  policyNumber: "",
  zipCode: "",
  emailToken: "",
  forgotPasswordEmail: "",
  resetPasswordEmail: "",
  hasError: false,
  registrationLinkExpired: {
    email: "",
    policyNumber: "",
    zipCode: ""
  }
};

const account = createSlice({
  name: OnlineAccountWrapperStateKey.ACCOUNT,
  initialState,
  reducers: {
    getUserInfoSuccessful: (state, action) => {
      const { email, zipCode, policyNumber } = action.payload;

      state.createPasswordEmail = email;
      state.policyNumber = policyNumber;
      state.zipCode = zipCode;
      state.registrationLinkExpired = {
        ...initialState.registrationLinkExpired
      };
    },
    saveEmailToken: (state, action) => {
      return { ...state, emailToken: action.payload };
    },
    createAccountSuccessful: state => state,
    createAccountFailed: state => state,
    saveForgotPasswordEmail: (state, action: PayloadAction<string>) => {
      return { ...state, forgotPasswordEmail: action.payload };
    },
    saveResetPasswordEmail: (state, action: PayloadAction<string>) => {
      return { ...state, resetPasswordEmail: action.payload };
    },
    sendRegistrationEmailSuccessful: (
      state,
      action: PayloadAction<RegistrationEmailPayload>
    ) => {
      const { policyNumber, zipCode, isExpiredResend } = action.payload;

      if (isExpiredResend) {
        state.policyNumber = initialState.policyNumber;
        state.zipCode = initialState.zipCode;
        state.registrationLinkExpired.policyNumber = policyNumber;
        state.registrationLinkExpired.zipCode = zipCode;
      } else {
        state.policyNumber = policyNumber;
        state.zipCode = zipCode;
        state.registrationLinkExpired = {
          ...initialState.registrationLinkExpired
        };
      }
      state.hasError = false;
    },
    sendRegistrationEmailFailed: state => {
      return { ...state, hasError: true };
    },
    saveRegistrationEmailResendPayload: (
      state,
      action: PayloadAction<{
        email: string;
        policyNumber: string;
        zipCode: string;
      }>
    ) => {
      state.policyNumber = initialState.policyNumber;
      state.zipCode = initialState.zipCode;
      state.registrationLinkExpired.email = action.payload.email;
      state.registrationLinkExpired.policyNumber = action.payload.policyNumber;
      state.registrationLinkExpired.zipCode = action.payload.zipCode;
    }
  },
  extraReducers: builder => {
    builder.addCase(clearAuthUser, (): AccountState => initialState);
    builder.addCase(LOCATION_CHANGE, (state: AccountState) => {
      return { ...state, hasError: false };
    });
  }
});

export const selectAccount = createSelector(
  (state: OnlineAccountWrapperApplicationState) => state.account,
  account => account
);

export const selectRegistrationEmailPayload = createSelector(
  selectAccount,
  account =>
    (Object.values(account.registrationLinkExpired).every(value => value !== "")
      ? {
          policyNumber: account.registrationLinkExpired.policyNumber,
          zipCode: account.registrationLinkExpired.zipCode,
          isExpiredResend: true
        }
      : {
          policyNumber: account.policyNumber,
          zipCode: account.zipCode
        }) as RegistrationEmailPayload
);

export enum AccountActionType {
  SEND_REGISTRATION_EMAIL = "account/sendRegistrationEmail",
  FORGOT_PASSWORD = "account/forgotPassword",
  GET_USER_INFO_BY_TOKEN = "account/getUserInfoByToken",
  CREATE_PASSWORD = "account/createPassword"
}

export const getUserInfoByTokenAction = createAction<
  string,
  AccountActionType.GET_USER_INFO_BY_TOKEN
>(AccountActionType.GET_USER_INFO_BY_TOKEN);

export const createPasswordAction = createAction<
  CreatePasswordPayload,
  AccountActionType.CREATE_PASSWORD
>(AccountActionType.CREATE_PASSWORD);

export const sendRegistrationEmailAction = createAction<
  RegistrationEmailPayload,
  AccountActionType.SEND_REGISTRATION_EMAIL
>(AccountActionType.SEND_REGISTRATION_EMAIL);

export const forgotPasswordAction = createAction<
  string,
  AccountActionType.FORGOT_PASSWORD
>(AccountActionType.FORGOT_PASSWORD);

export const accountActions = {
  ...account.actions,
  getUserInfoByTokenAction,
  createPasswordAction,
  sendRegistrationEmailAction,
  forgotPasswordAction
};

export default account.reducer;

import { ApiRoute, fetchOptions } from "./constants";

export interface BankPaymentSetupPayload {
  ownerData: string;
  token: string;
}

export const bankSetupApi = ({
  ownerData,
  token
}: BankPaymentSetupPayload): Promise<Response> =>
  fetch(ApiRoute.BANK_PAYMENT_SETUP, {
    ...fetchOptions,
    method: "POST",
    body: JSON.stringify({ ownerData, token })
  });

import { put } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { getUserByTokenApi } from "../../../api/getUserByToken";
import {
  call,
  enqueueSnackbar,
  HttpStatusCodes,
  PublicRoutes
} from "@pie/online-account-externals";
import { SnackbarStatus } from "../../../utils/constants";
import { accountActions, AccountActionType } from "../../account";
import { loadingActions } from "../../loadingState";
import { push } from "connected-react-router";

export interface GetUserInfoByTokenAction {
  payload: string;
  type: AccountActionType.GET_USER_INFO_BY_TOKEN;
}

export function* getUserInfoByTokenSaga(
  action: GetUserInfoByTokenAction
): SagaIterator {
  yield put(accountActions.saveEmailToken(action.payload));
  yield put(loadingActions.setLoading(true));
  try {
    const res = yield call(getUserByTokenApi, action.payload);
    if (res.ok) {
      const data = yield res.json();
      yield put(loadingActions.setLoading(false));
      return yield put(accountActions.getUserInfoSuccessful(data));
    } else if (res.status === HttpStatusCodes.BadRequest) {
      const data = yield res.json();
      const { type, data: responseData } = data;
      if (type === "EXPIRED_TOKEN") {
        yield put(
          accountActions.saveRegistrationEmailResendPayload({
            email: responseData.email,
            policyNumber: responseData.policyNumber,
            zipCode: responseData.zipCode
          })
        );
        yield put(push(PublicRoutes.CREATE_PASSWORD_EMAIL_LINK_EXPIRED));
      } else if (type === "INVALID_TOKEN") {
        yield put(push(PublicRoutes.CREATE_PASSWORD_EMAIL_LINK_INVALID));
      }
      return yield put(loadingActions.setLoading(false));
    }
    throw res;
  } catch (error) {
    console.error(error);
    yield put(loadingActions.setLoading(false));
    return yield put(enqueueSnackbar(SnackbarStatus.error));
  }
}

import {
  clearAuthUser,
  LayoutState,
  OnlineAccountWrapperApplicationState,
  OnlineAccountWrapperStateKey
} from "@pie/online-account-externals";
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

export const initialState: LayoutState = {
  isSideMenuOpen: false
};

const layoutSlice = createSlice({
  name: OnlineAccountWrapperStateKey.LAYOUT,
  initialState,
  reducers: {
    toggleSideMenu: (state, action: PayloadAction<boolean>) => {
      state.isSideMenuOpen = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(clearAuthUser, () => initialState);
  }
});

export const selectLayoutState = createSelector(
  (state: OnlineAccountWrapperApplicationState) =>
    state[OnlineAccountWrapperStateKey.LAYOUT],
  state => state
);

export const layoutActions = layoutSlice.actions;

export default layoutSlice.reducer;

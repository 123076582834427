import {
  createMiddleware as reduxBeaconCreateMiddleware,
  LoggerExtension,
  Target
} from "redux-beacon";
import { compose, Middleware, Store } from "@reduxjs/toolkit";

import { genericEventMap } from "./submaps/generic";
import { authEventMap } from "./submaps/auth";
import { accountEventMap } from "./submaps/account";
import { analyticsLogger } from "@api/analyticsLogger";
import { AnalyticsEvent, AnyEventMap } from "@pie/online-account-externals";
import { paymentSetupEventsMap } from "./submaps/paymentSetup";
import { termsAndConditionsEventsMap } from "./submaps/termsAndConditions";

export const loggerTarget =
  (): Target =>
  (events: AnalyticsEvent[]): void =>
    events.forEach(payload => analyticsLogger(payload));

const initialEvents = {
  ...genericEventMap,
  ...authEventMap,
  ...paymentSetupEventsMap,
  ...accountEventMap,
  ...termsAndConditionsEventsMap
};

export type AddDynamicEvents = (newEvents: AnyEventMap) => void;

interface AnalyticsEnhancedMiddleware {
  addDynamicEvents: AddDynamicEvents;
  middleware: MiddlewareReturn;
}

type MiddlewareReturn = (next: any) => (action: any) => any;

export const createAnalyticsMiddleware = (
  logger?: LoggerExtension
): AnalyticsEnhancedMiddleware => {
  let store: Store;
  let appliedDynamicEvents = {};
  let middlewareRef: Middleware;

  const createMiddleware = (events: AnyEventMap): MiddlewareReturn =>
    reduxBeaconCreateMiddleware(events, loggerTarget(), {
      logger
    });

  const addDynamicEvents: AddDynamicEvents = newEvents => {
    appliedDynamicEvents = { ...appliedDynamicEvents, ...newEvents };
    middlewareRef = createMiddleware({
      ...initialEvents,
      ...appliedDynamicEvents
    } as AnyEventMap)(store);
  };

  const middleware = (_store: Store): MiddlewareReturn => {
    store = _store;
    if (!middlewareRef) {
      middlewareRef = createMiddleware({ ...initialEvents })(store);
    }
    return (next: any) => (action: any) => compose(middlewareRef)(next)(action);
  };

  return { addDynamicEvents, middleware };
};

import { CallNow } from "@components/CallNow";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useErrorBoundaryStyles } from "./styles";

export const ErrorPage: React.FC = () => {
  const { callNowCard, content, gridContainer, header } =
    useErrorBoundaryStyles();

  return (
    <Grid
      container
      alignItems={"center"}
      justify={"center"}
      className={gridContainer}
    >
      <Grid item>
        <div className={content}>
          <Typography variant="h2" className={header}>
            Let's get in touch!
          </Typography>

          <Typography>Sorry, we are experiencing a technical issue.</Typography>

          <Typography>Please call so we can help you by phone.</Typography>
        </div>

        <div className={callNowCard}>
          <CallNow messageText={"Call now"} />
        </div>
      </Grid>
    </Grid>
  );
};

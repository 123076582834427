import {
  OnlineAccountWrapperStateKey,
  OnlineAccountWrapperApplicationState,
  TermsAndConditionsState
} from "@pie/online-account-externals";
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { authUserActions } from "@stores/authUser";

export const initialState: TermsAndConditionsState = {
  loading: false,
  isModalClosed: true
};

const termsAndConditionsSlice = createSlice({
  name: OnlineAccountWrapperStateKey.TERMS_AND_CONDITIONS,
  initialState,
  reducers: {
    acceptTermsAndConditions: state => {
      state.isModalClosed = true;
    },
    denyTermsAndConditions: state => {
      state.isModalClosed = true;
    },
    closeModal: state => {
      state.isModalClosed = true;
    },
    successfulResponse: (state, action: PayloadAction<boolean>) => {
      state.loading = false;
      state.isModalClosed = action.payload;
    },
    failedResponse: state => {
      state.loading = false;
      state.isModalClosed = true;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      authUserActions.setAuthUser,
      (state: TermsAndConditionsState) => {
        state.loading = true;
      }
    );
  }
});

export const selectIsTnCLoading = createSelector(
  (state: OnlineAccountWrapperApplicationState) =>
    state[OnlineAccountWrapperStateKey.TERMS_AND_CONDITIONS],
  state => state.loading
);

export const selectIsTncModalClosed = createSelector(
  (state: OnlineAccountWrapperApplicationState) =>
    state[OnlineAccountWrapperStateKey.TERMS_AND_CONDITIONS],
  state => state.isModalClosed
);

export const termsAndConditionsActions = termsAndConditionsSlice.actions;

export default termsAndConditionsSlice.reducer;

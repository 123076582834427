import { Grid, Typography } from "@material-ui/core";
import { accountActions, selectAccount } from "../../../store/account";
import { useDispatch, useSelector } from "react-redux";

import { AuthCardWrapper } from "@components/Auth";
import { CreateAccountLink } from "@components/Auth/LinkText/PreDefinedLinks";
import { LoadingButton } from "@pie/components";
import React from "react";
import { ReturnToLoginLink } from "@components/Auth/LinkText/PreDefinedLinks/ReturnToLoginLink";
import { maskEmail } from "@utils/helpers";
import { selectLoadingState } from "../../../store/loadingState";
import { useAuthStyles } from "../styles";

export const headerText = "Password reset email sent";
export const resetEmailText = "We sent a password reset email to:";
export const sendAgainText = "Send Again";

export const ResetEmailSent: React.FC = () => {
  const dispatch = useDispatch();
  const { forgotPasswordEmail } = useSelector(selectAccount);
  const loading = useSelector(selectLoadingState);
  const { button } = useAuthStyles();

  const handleSendAgain = () => {
    dispatch(accountActions.forgotPasswordAction(forgotPasswordEmail));
  };

  return (
    <AuthCardWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h2" color="textPrimary" align="center">
            {headerText}
          </Typography>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Typography
            variant="body1"
            paragraph
            style={{
              wordBreak: "normal"
            }}
          >
            {resetEmailText}
          </Typography>
          <Typography
            variant="body1"
            align="center"
            paragraph
            style={{
              wordBreak: "normal"
            }}
          >
            {maskEmail(forgotPasswordEmail)}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{
              wordBreak: "normal"
            }}
          >
            If you don't receive the email, check your spam folder or send the
            email again using the <i>Send Again</i> button below.
          </Typography>
        </Grid>
        <Grid item container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <LoadingButton
              loading={loading as boolean}
              className={button}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSendAgain}
            >
              {sendAgainText}
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid item container spacing={1} direction="column" alignItems="center">
          <Grid item>
            <ReturnToLoginLink />
          </Grid>
          <Grid item>
            <CreateAccountLink />
          </Grid>
        </Grid>
      </Grid>
    </AuthCardWrapper>
  );
};

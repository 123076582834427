import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { lightFormat, parseISO } from "date-fns";
import { Card, useIsMobile } from "@pie/components";
import { faUserShield } from "@fortawesome/pro-solid-svg-icons/faUserShield";
import { faLock } from "@fortawesome/pro-solid-svg-icons/faLock";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { usePaymentCardStyles } from "./styles";
import { dateFormat, PaymentState } from "@pie/online-account-externals";

export type PaymentDetailsCardProps = Omit<PaymentState, "hasError">;

export enum PaymentDetailsCardLabels {
  POLICY_NUMBER_LABEL = "Policy Number",
  PAYMENT_PLAN_LABEL = "Payment Plan",
  DEPOSIT_AMOUNT_LABEL = "Deposit Amount"
}

export const PaymentDetailsCard: React.FC<PaymentDetailsCardProps> = ({
  isIssued,
  businessName,
  policyNumber,
  paymentPlan,
  depositAmount,
  effectiveDate,
  expirationDate
}) => {
  const {
    typography,
    iconSpacing,
    iconColor,
    dataContainer,
    dateStyle,
    encryptionStyle
  } = usePaymentCardStyles();
  const isMobile = useIsMobile();
  return (
    <Card displayStyle="tertiary">
      <Typography
        variant={isMobile ? "subtitle1" : "h2"}
        className={typography}
        paragraph
      >
        <FontAwesomeIcon
          icon={faUserShield}
          className={clsx(iconSpacing, iconColor)}
        />
        {businessName}
      </Typography>
      <Grid container justify="space-between" className={dataContainer}>
        <Typography className={typography}>
          {PaymentDetailsCardLabels.POLICY_NUMBER_LABEL}
        </Typography>
        <Typography>{policyNumber}</Typography>
      </Grid>
      <Grid container justify="space-between" className={dataContainer}>
        <Typography className={typography}>
          {PaymentDetailsCardLabels.PAYMENT_PLAN_LABEL}
        </Typography>
        <Typography>{paymentPlan?.label}</Typography>
      </Grid>
      {!isIssued ? (
        <Grid container justify="space-between" className={dataContainer}>
          <Typography className={typography}>
            {PaymentDetailsCardLabels.DEPOSIT_AMOUNT_LABEL}
          </Typography>
          <Typography>{`$${depositAmount?.toLocaleString()}`}</Typography>
        </Grid>
      ) : null}
      <Grid container justify="center">
        {!isIssued ? (
          <Typography className={clsx(dataContainer, dateStyle)}>
            {`Effective on ${lightFormat(
              parseISO(effectiveDate as string),
              dateFormat
            )}`}
          </Typography>
        ) : (
          <Typography className={clsx(dataContainer, dateStyle)}>
            {`Expires on ${lightFormat(
              parseISO(expirationDate as string),
              dateFormat
            )}`}
          </Typography>
        )}
        <Typography
          color="textSecondary"
          variant="body2"
          className={encryptionStyle}
        >
          <FontAwesomeIcon icon={faLock} className={iconSpacing} />
          Protected by TLS encryption
        </Typography>
      </Grid>
    </Card>
  );
};

import React, { useCallback, useState } from "react";
import {
  LoadingButton,
  Password,
  TextInputInfoLabel,
  useAuth
} from "@pie/components";
import { Grid } from "@material-ui/core";

import { PublicRoutes } from "@pie/online-account-externals";
import { useAuthStyles } from "../styles";
import { AuthCardWrapper } from "../AuthCardWrapper";
import {
  AgencyPartnerLink,
  CreateAccountLink
} from "../LinkText/PreDefinedLinks";
import { FieldName } from "@utils/constants";
import { LinkText } from "../LinkText";

export const loginButtonText = "Login";
export const emailInputLabel = "Email";

export const forgotPasswordText = "Forgot password?";

const { PASSWORD, EMAIL } = FieldName;

export const SignIn: React.FC = () => {
  const { authFormData, login, loading } = useAuth();
  const { button } = useAuthStyles();

  const [email, setEmail] = useState<string>(
    authFormData?.payload?.email || ""
  );
  const [password, setPassword] = useState<string>("");

  const normalizeEmail = (value: string): string =>
    value ? value.trim() : value;

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.currentTarget;
      const normalizedValue = normalizeEmail(value);
      setEmail(normalizedValue);
    },
    [setEmail]
  );

  const handlePasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.currentTarget;
      setPassword(value);
    },
    [setPassword]
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent): void => {
      e.preventDefault();
      if (email && password) {
        login({ email, password });
      }
    },
    [login, email, password]
  );

  return (
    <AuthCardWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <form onSubmit={handleSubmit}>
            <Grid item container direction="column">
              <Grid item>
                <TextInputInfoLabel
                  required
                  id={EMAIL}
                  type="text"
                  label={emailInputLabel}
                  name={EMAIL}
                  value={email}
                  onChange={handleEmailChange}
                  normalizer={normalizeEmail}
                />
              </Grid>
              <Grid item>
                <Password
                  id={PASSWORD}
                  label="Password"
                  name={PASSWORD}
                  value={password}
                  onChange={handlePasswordChange}
                />
              </Grid>
              <Grid item container justify="center">
                <LoadingButton
                  loading={loading}
                  className={button}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!email || !password}
                >
                  {loginButtonText}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item container spacing={1} direction="column" alignItems="center">
          <Grid item>
            <LinkText to={PublicRoutes.FORGOT_PASSWORD}>
              {forgotPasswordText}
            </LinkText>
          </Grid>
          <Grid item>
            <CreateAccountLink />
          </Grid>
          <Grid item>
            <AgencyPartnerLink />
          </Grid>
        </Grid>
      </Grid>
    </AuthCardWrapper>
  );
};

import { ApiRoute, fetchOptions } from "./constants";

export const acceptTermsAndConditionsApi = (
  idToken: string
): Promise<Response> =>
  fetch(`${ApiRoute.TERMS_AND_CONDITIONS_BASE}`, {
    method: "POST",
    ...fetchOptions,
    headers: { ...fetchOptions.headers, Authorization: `Bearer ${idToken}` }
  });

export const getTermsAndConditionsAcceptedApi = (
  idToken: string
): Promise<Response> =>
  fetch(`${ApiRoute.TERMS_AND_CONDITIONS_BASE}`, {
    method: "GET",
    ...fetchOptions,
    headers: { ...fetchOptions.headers, Authorization: `Bearer ${idToken}` }
  });

import { ApiRoute, fetchOptions } from "@api/constants";
import { SaveAuditPayload } from "@stores/auditIntake";

export const getAuditIntakeApi = (
  idToken: string,
  policyNumber: string
): Promise<Response> =>
  fetch(`${ApiRoute.AUDIT_INTAKE_BASE_ROUTE}/${policyNumber}`, {
    method: "GET",
    ...fetchOptions,
    headers: { ...fetchOptions.headers, Authorization: `Bearer ${idToken}` }
  });

export const startAuditIntakeApi = (
  idToken: string,
  policyNumber: string
): Promise<Response> =>
  fetch(`${ApiRoute.AUDIT_INTAKE_BASE_ROUTE}/${policyNumber}/start`, {
    method: "PUT",
    ...fetchOptions,
    headers: { ...fetchOptions.headers, Authorization: `Bearer ${idToken}` }
  });

export const finalizeAuditIntakeApi = (
  idToken: string,
  policyNumber: string
): Promise<Response> =>
  fetch(`${ApiRoute.AUDIT_INTAKE_BASE_ROUTE}/${policyNumber}/finalize`, {
    method: "PUT",
    ...fetchOptions,
    headers: { ...fetchOptions.headers, Authorization: `Bearer ${idToken}` }
  });

export const saveAuditIntakeApi = (
  idToken: string,
  payload: SaveAuditPayload
): Promise<Response> =>
  fetch(`${ApiRoute.AUDIT_INTAKE_BASE_ROUTE}/${payload.policyNumber}`, {
    method: "PATCH",
    body: JSON.stringify(payload.audit),
    ...fetchOptions,
    headers: { ...fetchOptions.headers, Authorization: `Bearer ${idToken}` }
  });

import {
  OnlineAccountWrapperApplicationState,
  OnlineAccountWrapperStateKey
} from "@pie/online-account-externals";
import { AnyAction, combineReducers, Reducer } from "@reduxjs/toolkit";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

import authUser from "../authUser";
import analyticsUser from "../analytics";
import snackbar from "../snackbar";
import paymentSetup from "../paymentSetup";
import loadingState from "../loadingState";
import layoutReducer from "../layout";
import healthCheckReducer from "../healthCheck";
import accountReducer from "../account";
import tncReducer from "../termsAndConditions";
import { leaveModalReducer } from "@stores/leaveModal";
import auditIntakeReducer from "../auditIntake";

export const createRootReducer = (
  history: History,
  asyncReducers?: { [key: string]: Reducer }
): Reducer<OnlineAccountWrapperApplicationState> =>
  combineReducers<OnlineAccountWrapperApplicationState>({
    ...asyncReducers,
    [OnlineAccountWrapperStateKey.ROUTER]: connectRouter(history) as Reducer<
      RouterState<unknown>,
      AnyAction
    >,
    [OnlineAccountWrapperStateKey.AUTH_USER]: authUser,
    [OnlineAccountWrapperStateKey.ANALYTICS_USER]: analyticsUser,
    [OnlineAccountWrapperStateKey.SNACKBAR]: snackbar,
    [OnlineAccountWrapperStateKey.PAYMENT]: paymentSetup,
    [OnlineAccountWrapperStateKey.LOADING]: loadingState,
    [OnlineAccountWrapperStateKey.LAYOUT]: layoutReducer,
    [OnlineAccountWrapperStateKey.HEALTH_CHECK]: healthCheckReducer,
    [OnlineAccountWrapperStateKey.ACCOUNT]: accountReducer,
    [OnlineAccountWrapperStateKey.TERMS_AND_CONDITIONS]: tncReducer,
    [OnlineAccountWrapperStateKey.LEAVE_MODAL]: leaveModalReducer,
    [OnlineAccountWrapperStateKey.AUDIT_INTAKE]: auditIntakeReducer
  });

export const createRootReducerWithErrorHandling = (
  history: History,
  errorCallback: (exception: any) => void,
  asyncReducers?: { [key: string]: Reducer }
): Reducer<OnlineAccountWrapperApplicationState> => {
  const reducer = createRootReducer(history, asyncReducers);
  return (
    state: OnlineAccountWrapperApplicationState | undefined,
    action: AnyAction
  ): OnlineAccountWrapperApplicationState => {
    try {
      return reducer(state, action);
    } catch (e) {
      console.error(e);
      errorCallback(e);
      return state as OnlineAccountWrapperApplicationState;
    }
  };
};

export const onlineAccountWrapperUISelector = (
  state: OnlineAccountWrapperApplicationState
): OnlineAccountWrapperApplicationState => state;

import { makeStyles, Theme } from "@material-ui/core";

export const useLinkExpiredStyle = makeStyles((theme: Theme) => ({
  containerStyle: {
    textAlign: "center"
  },
  buttonStyle: {
    margin: theme.spacing(5, 0)
  }
}));

import React, { useContext, useMemo } from "react";
import { Divider, Grid, Link, Typography } from "@material-ui/core";
import { ExternalLinks } from "../../utils/constants";
import { useFooterStyles } from "./styles";
import { ReportAccident } from "./ReportAccident/ReportAccident";
import { CustomerCare } from "./CustomerCare/CustomerCare";
import { getAuthContext, useIsMobile } from "@pie/components";

export const CONTACT_TITLE = "Contact";
export const ABOUT_PIE_TITLE = "About Pie Insurance";
export const TERMS_OF_USE_TITLE = "Terms of use";

const BottomFooter: React.FC = () => {
  const { bottomFooter } = useFooterStyles();

  return (
    <Grid
      className={bottomFooter}
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid item>
        <Grid direction="row" container wrap="nowrap">
          <Typography variant="caption">
            <Link
              href={ExternalLinks.TERMS_OF_USE_LINK}
              target="_blank"
              rel="noopener"
              noWrap
            >
              {TERMS_OF_USE_TITLE}
            </Link>
          </Typography>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography variant="caption">
            <Link
              href={ExternalLinks.CONTACT_LINK}
              target="_blank"
              rel="noopener"
              noWrap
            >
              {CONTACT_TITLE}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TopFooter: React.FC = () => {
  const { topFooter } = useFooterStyles();

  return (
    <Grid className={topFooter} container direction="row" wrap="nowrap">
      <Grid item>
        <CustomerCare />
      </Grid>
      <Grid item>
        <ReportAccident />
      </Grid>
    </Grid>
  );
};

export const Footer: React.FC = () => {
  const isMobile = useIsMobile();
  const { isAuthenticated } = useContext(getAuthContext());

  const showTopFooter = useMemo(
    () => !isAuthenticated || !isMobile,
    [isAuthenticated, isMobile]
  );

  return (
    <Grid direction="column" container>
      {showTopFooter && <TopFooter />}
      <BottomFooter />
    </Grid>
  );
};

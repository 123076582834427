import { Grid, Typography } from "@material-ui/core";
import { CheckCancel } from "@pie/components";
import React from "react";
import { CallNow } from "../../components/CallNow";
import { useTerminalPageStyle } from "./styles";

export type PageType = "error" | "success";

export interface TerminalPageProps {
  pageType: PageType;
  header?: string | React.ReactNode;
  mainText?: string;
  subText?: string;
  callBoxText?: string;
}

export const TerminalPage: React.FC<TerminalPageProps> = ({
  pageType,
  header,
  mainText,
  subText,
  callBoxText
}) => {
  const {
    containerStyle,
    mainTextStyle,
    subTextStyle,
    callBoxContainer,
    headerStyle
  } = useTerminalPageStyle({ pageType });
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      className={containerStyle}
    >
      <Grid item>
        {pageType === "success" ? (
          <Typography align="center" variant="h1" className={headerStyle}>
            <CheckCancel checked fontSize="default" /> {header || "Success"}
          </Typography>
        ) : (
          <Typography align="center" variant="h1" className={headerStyle}>
            {header || "Let's get in touch"}
          </Typography>
        )}

        {mainText && (
          <Typography align="center" variant="h2" className={mainTextStyle}>
            {mainText}
          </Typography>
        )}

        {subText && (
          <Typography align="center" variant="h2" className={subTextStyle}>
            {subText}
          </Typography>
        )}
      </Grid>
      <div className={callBoxContainer}>
        <CallNow messageText={callBoxText} />
      </div>
    </Grid>
  );
};

import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import {
  emailValidator,
  LoadingButton,
  TextInputInfoLabel,
  useIsMobile
} from "@pie/components";
import { AuthCardWrapper } from "../AuthCardWrapper";
import { useAuthStyles } from "../styles";
import {
  CreateAccountLink,
  AgencyPartnerLink
} from "../LinkText/PreDefinedLinks";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "../../../store/authUser";
import { accountActions } from "../../../store/account";

export const resetPasswordText = "Reset password";
export const emailErrorText = "Please enter a valid email";
export const emailLabel = "Email";

export const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(selectAuthUser);
  const [email, setEmail] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const { value } = e.currentTarget;
      setIsValidEmail(emailValidator(value));
      setEmail(value);
    },
    [setIsValidEmail, setEmail]
  );

  const handleEmailBlur = useCallback((): void => {
    if (!isDirty) setIsDirty(true);
  }, [isDirty]);

  const handleSubmit = useCallback(
    (e: React.FormEvent): void => {
      e.preventDefault();
      dispatch(accountActions.forgotPasswordAction(email));
    },
    [dispatch, email]
  );

  const { button } = useAuthStyles();
  return (
    <AuthCardWrapper>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <form onSubmit={handleSubmit}>
            <Grid item container direction="column">
              <TextInputInfoLabel
                required
                id="forgot-password"
                type="email"
                name="forgot-password"
                label={emailLabel}
                value={email}
                error={isDirty && !isValidEmail}
                errorText={(isDirty && !isValidEmail && emailErrorText) || ""}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
              />
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                spacing={3}
              >
                <Grid item style={{ width: isMobile ? "100%" : "unset" }}>
                  <LoadingButton
                    loading={loading as boolean}
                    className={button}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValidEmail}
                    fullWidth={isMobile}
                  >
                    {resetPasswordText}
                  </LoadingButton>
                </Grid>
                <Grid
                  item
                  container
                  spacing={1}
                  direction="column"
                  alignItems="center"
                >
                  <Grid item>
                    <CreateAccountLink />
                  </Grid>
                  <Grid item>
                    <AgencyPartnerLink />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </AuthCardWrapper>
  );
};

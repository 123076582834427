import { Grid, Typography } from "@material-ui/core";
import { accountActions, selectAccount } from "../../../store/account";
import { useDispatch, useSelector } from "react-redux";

import { AuthCardWrapper } from "@components/Auth";
import { LoadingButton } from "@pie/components";
import React from "react";
import { ReturnToLoginLink } from "@components/Auth/LinkText/PreDefinedLinks/ReturnToLoginLink";
import { maskEmail } from "@utils/helpers";
import { selectLoadingState } from "../../../store/loadingState";

export const headerText = "Your reset password link has expired";
export const expiredLinkText =
  "This link has expired for security reasons. Please request a new link to the following email:";
export const secondaryExpiredLinkText =
  "The new link will be valid for 1 hour.";
export const resendText = "Resend reset email";

export const ResetPasswordLinkExpired: React.FC = () => {
  const dispatch = useDispatch();
  const { resetPasswordEmail } = useSelector(selectAccount);
  const loading = useSelector(selectLoadingState);

  const handleSendAgain = () => {
    dispatch(accountActions.forgotPasswordAction(resetPasswordEmail));
  };

  return (
    <AuthCardWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h2" color="textPrimary" align="center">
            {headerText}
          </Typography>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Typography
            variant="body1"
            paragraph
            style={{
              wordBreak: "normal"
            }}
          >
            {expiredLinkText}
          </Typography>
          <Typography
            align="center"
            variant="body1"
            paragraph
            style={{
              wordBreak: "break-word"
            }}
          >
            {maskEmail(resetPasswordEmail)}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            style={{
              wordBreak: "normal"
            }}
          >
            {secondaryExpiredLinkText}
          </Typography>
        </Grid>
        <Grid item container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <LoadingButton
              loading={loading as boolean}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSendAgain}
            >
              {resendText}
            </LoadingButton>
          </Grid>
          <Grid item>
            <ReturnToLoginLink />
          </Grid>
        </Grid>
      </Grid>
    </AuthCardWrapper>
  );
};

import { InternalCardSetupData } from "@pie/online-account-externals/*";
import { fetchOptions, ApiRoute, ApiPath } from "../constants";

export interface InternalCardPaymentSetupPayload extends InternalCardSetupData {
  idToken: string;
}

export const internalCardSetupApi = (
  payload: InternalCardPaymentSetupPayload
): Promise<Response> => {
  const { idToken, stripeSourceToken, policyNumber } = payload;
  return fetch(`${ApiRoute.INTERNAL_PAYMENT_SETUP_BASE}${ApiPath.CARD_PATH}`, {
    ...fetchOptions,
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${idToken}`
    },
    method: "POST",
    body: JSON.stringify({ stripeSourceToken, policyNumber })
  });
};

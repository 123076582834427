import { ApiRoute, fetchOptions, ApiPath } from "./constants";
import { RegistrationEmailPayload } from "../store/sagas/sendRegistrationEmail/sendRegistrationEmailSaga";

export const sendRegistrationEmailApi = (
  payload: RegistrationEmailPayload
): Promise<Response> =>
  fetch(`${ApiRoute.BASE_USER_ROUTE}${ApiPath.SEND_REGISTRATION_EMAIL}`, {
    ...fetchOptions,
    method: "POST",
    body: JSON.stringify({
      zipCode: payload.zipCode,
      policyNumber: payload.policyNumber.toUpperCase()
    })
  });

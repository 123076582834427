import { paymentSetupLandingApi } from "../../../api/paymentSetupLanding";
import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { paymentActions, PaymentActionType } from "../../paymentSetup";
import {
  call,
  HttpStatusCodes,
  PublicRoutes,
  selectEmailToken
} from "@pie/online-account-externals";
import { push } from "connected-react-router";

export interface PaymentSetupLandingAction {
  payload: { isIssued: boolean };
  type: PaymentActionType.GET_POLICY_INFO;
}

export function* paymentSetupLandingSaga(
  action: PaymentSetupLandingAction
): SagaIterator {
  try {
    const emailToken = yield select(selectEmailToken);
    const {
      payload: { isIssued }
    } = action;

    const res = yield call(paymentSetupLandingApi, { emailToken });

    if (res.ok) {
      const {
        data: {
          userLegalName,
          userEmailAddress,
          policySummary,
          actualIsIssued,
          updatedEmailToken
        }
      }: any = yield res.json();

      return yield put(
        paymentActions.paymentSetupLandingSuccessful({
          userLegalName,
          userEmailAddress,
          ...policySummary,
          isIssued: actualIsIssued,
          emailToken: updatedEmailToken || emailToken
        })
      );
    } else {
      const {
        data,
        errors: { errorType }
      } = yield res.json();
      if (
        res.status === HttpStatusCodes.BadRequest &&
        errorType[0] === "expiredToken"
      ) {
        yield put(paymentActions.emailTokenExpired({ data, isIssued }));
        return yield put(push(PublicRoutes.PAYMENT_LINK_EXPIRED));
      }

      yield put(paymentActions.paymentSetupLandingFailed());

      if (errorType[0] === "policyLocked") {
        return yield put(push(PublicRoutes.POLICY_UNAVAILABLE_ERROR));
      }
      return yield put(push(PublicRoutes.GENERAL_ERROR));
    }
  } catch (error) {
    yield put(paymentActions.paymentSetupLandingFailed());
    return yield put(push(PublicRoutes.GENERAL_ERROR));
  }
}

import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { useFooterStyles } from "./styles";

export interface FooterProps {
  onAcceptClick: () => void;
  onDeclineClick: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  onAcceptClick,
  onDeclineClick
}) => {
  const styles = useFooterStyles();

  return (
    <Grid container spacing={2} className={styles.container}>
      <Box clone order={{ xs: 2, sm: 1 }}>
        <Grid item>
          <Button variant="text" color="primary" onClick={onDeclineClick}>
            Decline
          </Button>
        </Grid>
      </Box>
      <Box clone order={{ xs: 1, sm: 2 }}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onAcceptClick}>
            Accept
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};

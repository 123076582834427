import {
  HttpStatusCodes,
  OnlineAccountWrapperApplicationState,
  OnlineAccountWrapperStateKey,
  apiStatusCodeReceived,
  ApiStatusCodeReceived,
  HealthCheckState
} from "@pie/online-account-externals";
import {
  createSlice,
  createSelector,
  PayloadAction,
  createAction
} from "@reduxjs/toolkit";

export const initialState: HealthCheckState = {
  hasDegradedApi: {},
  hasDegradedHealthCheck: false
};

const healthCheckSlice = createSlice({
  name: OnlineAccountWrapperStateKey.HEALTH_CHECK,
  initialState,
  reducers: {
    serviceHealthCheckCompleted: (state, action: PayloadAction<boolean>) => {
      state.hasDegradedHealthCheck = !action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      apiStatusCodeReceived,
      (state, action: PayloadAction<ApiStatusCodeReceived>) => {
        const { apiName, statusCode } = action.payload;
        state.hasDegradedApi[apiName] =
          statusCode === HttpStatusCodes.ServiceUnavailable;
      }
    );
  }
});

export const checkServiceHealth = createAction(
  `${OnlineAccountWrapperStateKey.HEALTH_CHECK}/checkServiceHealth`
);

export const selectDegradedServiceStatus = createSelector(
  (state: OnlineAccountWrapperApplicationState) =>
    state[OnlineAccountWrapperStateKey.HEALTH_CHECK],
  state =>
    state.hasDegradedHealthCheck ||
    Object.values(state.hasDegradedApi).some(Boolean)
);

export const healthCheckActions = {
  ...healthCheckSlice.actions,
  checkServiceHealth
};

export default healthCheckSlice.reducer;

import React from "react";
import { LinkText } from "../LinkText";
import { PublicRoutes } from "@pie/online-account-externals";
import { useLinkStyles } from "./styles";

export const createAccountText = "Create an account";
export const newToPieText = "New to Pie?";

export const CreateAccountLink: React.FC = () => {
  const { createAccountStyle } = useLinkStyles();
  return (
    <LinkText
      to={PublicRoutes.CREATE_ACCOUNT}
      prefixText={newToPieText}
      className={createAccountStyle}
    >
      {createAccountText}
    </LinkText>
  );
};

import React, { useCallback } from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import { useResourcesCardStyles } from "./styles";
import { LinkComponent } from "./LinkComponent";
import { ExternalLinks } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/pro-solid-svg-icons/faClipboardCheck";
import { faUserHardHat } from "@fortawesome/pro-solid-svg-icons/faUserHardHat";
import { faUserInjured } from "@fortawesome/pro-solid-svg-icons/faUserInjured";
import { faQuestionSquare } from "@fortawesome/pro-solid-svg-icons/faQuestionSquare";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export enum ResourcesCardLabels {
  RESOURCES_LABEL = "Resources",
  FAQ_LABEL = "FAQ",
  ABOUT_WC_LABEL = "About workers' comp insurance",
  HOW_TO_RESPOND_LABEL = "How to respond to a workplace injury",
  AUDITS_LABEL = "Audits"
}

export const ResourcesCard: React.FC = () => {
  const styles = useResourcesCardStyles();
  const {
    FAQ_LINK,
    WORKERS_COMP_LINK,
    HOW_TO_RESPOND_TO_WORKPLACE_INJURY,
    AUDIT_LINK
  } = ExternalLinks;

  const {
    RESOURCES_LABEL,
    FAQ_LABEL,
    ABOUT_WC_LABEL,
    HOW_TO_RESPOND_LABEL,
    AUDITS_LABEL
  } = ResourcesCardLabels;

  const Content = useCallback(
    () => (
      <>
        <LinkComponent
          href={FAQ_LINK}
          icon={
            <FontAwesomeIcon icon={faQuestionSquare as IconProp} size={"lg"} />
          }
        >
          {FAQ_LABEL}
        </LinkComponent>
        <LinkComponent
          href={WORKERS_COMP_LINK}
          icon={
            <FontAwesomeIcon icon={faUserHardHat as IconProp} size={"lg"} />
          }
        >
          {ABOUT_WC_LABEL}
        </LinkComponent>
        <LinkComponent
          href={HOW_TO_RESPOND_TO_WORKPLACE_INJURY}
          icon={
            <FontAwesomeIcon icon={faUserInjured as IconProp} size={"lg"} />
          }
        >
          {HOW_TO_RESPOND_LABEL}
        </LinkComponent>
        <LinkComponent
          href={AUDIT_LINK}
          icon={
            <FontAwesomeIcon icon={faClipboardCheck as IconProp} size={"lg"} />
          }
        >
          {AUDITS_LABEL}
        </LinkComponent>
      </>
    ),
    [
      FAQ_LINK,
      WORKERS_COMP_LINK,
      HOW_TO_RESPOND_TO_WORKPLACE_INJURY,
      AUDIT_LINK
    ]
  );

  return (
    <Card variant="outlined">
      <CardHeader
        title={RESOURCES_LABEL}
        titleTypographyProps={{ className: styles.typography }}
      />
      <CardContent>
        <Content />
      </CardContent>
    </Card>
  );
};

import { fetchOptions, ApiRoute, ApiPath } from "./constants";

export interface ResendPaymentEmailRequestPayload {
  token: string;
  email: string;
  isIssued: boolean;
}

export const resendPaymentSetupEmailApi = (
  payload: ResendPaymentEmailRequestPayload
): Promise<Response> =>
  fetch(`${ApiRoute.PAYMENT_SETUP_BASE_ROUTE}${ApiPath.RESEND_PAYMENT_EMAIL}`, {
    ...fetchOptions,
    method: "POST",
    body: JSON.stringify({ ...payload })
  });

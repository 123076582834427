import { put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { SagaIterator } from "redux-saga";
import {
  PublicRoutes,
  enqueueSnackbar,
  call,
  HttpStatusCodes
} from "@pie/online-account-externals";
import { CustomerCarePhoneNumber, SnackbarStatus } from "@utils/constants";
import { onlineAccountForgotPasswordRequest } from "@components/Auth/api";
import { AccountActionType, accountActions } from "../../account";
import { loadingActions } from "../../loadingState";

export type ForgotPasswordPayload = {
  email: string;
};
export interface ForgotPasswordAction {
  payload: string;
  type: AccountActionType.FORGOT_PASSWORD;
}

export const successSnackbarMessage =
  "Email has been sent. Please check your inbox or spam folder.";
export const errorSnackbarMessage = `Email cannot be sent. Please contact us at \n ${CustomerCarePhoneNumber}`;

export function* forgotPasswordSaga(
  action: ForgotPasswordAction
): SagaIterator {
  yield put(loadingActions.setLoading(true));
  yield put(accountActions.saveForgotPasswordEmail(action.payload));
  try {
    const res = yield call(onlineAccountForgotPasswordRequest, action.payload);
    if (res.ok) {
      yield put(loadingActions.setLoading(false));
      yield put(
        enqueueSnackbar({
          ...SnackbarStatus.emailSendSuccess,
          message: successSnackbarMessage
        })
      );
      return yield put(push(PublicRoutes.RESET_EMAIL_SENT));
    }
    if (res.status >= HttpStatusCodes.InternalServerError) {
      yield put(loadingActions.setLoading(false));
      return yield put(enqueueSnackbar(SnackbarStatus.error));
    }
    if (res.status >= HttpStatusCodes.BadRequest) {
      return yield put(
        enqueueSnackbar({
          message: errorSnackbarMessage,
          options: {
            variant: "error"
          }
        })
      );
    }
    throw res;
  } catch (error) {
    yield put(loadingActions.setLoading(false));
    return yield put(enqueueSnackbar(SnackbarStatus.error));
  }
}

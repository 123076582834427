import { makeStyles, Theme } from "@material-ui/core";

type StyleArgs = {
  pageType: "success" | "error";
};

export const useTerminalPageStyle = makeStyles((theme: Theme) => ({
  containerStyle: {
    margin: "auto auto"
  },
  headerStyle: {
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(1.5)
  },
  mainTextStyle: {
    fontWeight: ({ pageType }: StyleArgs) =>
      pageType === "success" ? theme.typography.fontWeightMedium : "inherit"
  },
  subTextStyle: {
    marginTop: theme.spacing(2.5)
  },
  callBoxContainer: {
    marginTop: theme.spacing(4)
  }
}));

import { makeStyles, Theme } from "@material-ui/core";

const topBarSpacingMagicNumber = 17;

export const useLayoutStyles = makeStyles(
  (theme: Theme) => ({
    headerContainer: {
      position: "sticky",
      top: 0,
      zIndex: 1
    },
    appContent: {
      alignItems: "stretch",
      display: "flex",
      flex: "1 1 auto"
    },
    tabContent: {
      padding: theme.spacing(2, 5),
      flex: "1 1 auto",
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(3),
        padding: theme.spacing(0, 3)
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
      }
    },
    childStyles: {
      "& > div": {
        width: "100%"
      },
      flexDirection: "column"
    },
    childContent: {
      display: "flex",
      flex: 1
    },
    cardContainer: {
      position: "sticky",
      alignSelf: "flex-start",
      top: theme.spacing(topBarSpacingMagicNumber),
      flexGrow: 1,
      [theme.breakpoints.down("md")]: {
        position: "static",
        margin: theme.spacing(0, 0, 6)
      },
      "& > div:not(:last-child)": {
        marginBottom: theme.spacing(2.5)
      }
    },
    serviceAlert: {
      marginBottom: theme.spacing(2)
    },
    tabs: {
      position: "sticky",
      top: 0,
      zIndex: 1,
      background: theme.palette.grey[100],
      margin: theme.spacing(0),
      "& button": {
        minWidth: "120px",
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(14)
      },
      "& .Mui-selected": {
        fontWeight: theme.typography.fontWeightBold
      },
      [theme.breakpoints.down("md")]: {
        margin: 0
      }
    }
  }),
  { name: "Layout" }
);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const tabStyles = (theme: Theme) => ({
  scroller: {
    marginBottom: theme.spacing(1.5)
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    height: "3px",
    "& > span": {
      maxWidth: "90px",
      width: "100%",
      backgroundColor: theme.palette.primary.main
    }
  }
});

import React from "react";
import { Grid, Typography, Button, Link } from "@material-ui/core";
import { LinkText } from "..";
import { AuthCardWrapper } from "../AuthCardWrapper";
import { useDispatch, useSelector } from "react-redux";
import { PublicRoutes } from "@pie/online-account-externals";
import { CustomerCarePhoneNumber } from "../../../utils/constants";
import { useAuthStyles } from "../styles";
import { loginLabel } from "./SendRegistrationEmail";
import {
  accountActions,
  selectRegistrationEmailPayload
} from "../../../store/account";

export const sendAgainText = "Send again";

export const EmailSendSuccess: React.FC<unknown> = () => {
  const { titleStyle } = useAuthStyles();
  const dispatch = useDispatch();

  const registrationEmailPayload = useSelector(selectRegistrationEmailPayload);

  const sendEmailAgain = () => {
    return dispatch(
      accountActions.sendRegistrationEmailAction(registrationEmailPayload)
    );
  };

  return (
    <AuthCardWrapper>
      <Typography paragraph variant="subtitle1" color="textPrimary">
        We sent an email to the account associated with this policy!
      </Typography>
      <Typography paragraph color="textSecondary">
        If you don't receive the email check your spam folder or send the email
        again using the "Send again" button below.
      </Typography>
      <Typography paragraph color="textSecondary">
        If you believe the email associated with this policy is incorrect,
        contact us at{" "}
        <Link href={`tel:${CustomerCarePhoneNumber}`}>
          {CustomerCarePhoneNumber}
        </Link>
        .
      </Typography>

      <Grid container justify="center" alignItems="center" direction="column">
        <Button
          variant="contained"
          color="primary"
          className={titleStyle}
          onClick={sendEmailAgain}
        >
          {sendAgainText}
        </Button>
        <LinkText to={PublicRoutes.SIGN_IN}>{loginLabel}</LinkText>
      </Grid>
    </AuthCardWrapper>
  );
};

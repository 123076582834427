import {
  AnalyticsActionPayload,
  AnalyticsEvent,
  AnyEventMap,
  OnlineAccountWrapperApplicationState
} from "@pie/online-account-externals";
import {
  AuthEventLoginFailedPayload,
  AuthEventLoginSuccessfulPayload
} from "@pie/components";

import { createAction } from "@reduxjs/toolkit";
import { getActionName } from "../utils";

export enum AnalyticsAuthName {
  SIGN_IN_SUCCESS = "Sign In Success",
  SIGN_IN_FAILED = "Sign In Failed"
}

enum AnalyticsAuthActionType {
  AUTHENTICATED_ACTION = "analytics/authenticatedAction",
  UNAUTHENTICATED_ACTION = "analytics/unauthenticatedAction"
}

const authenticated = createAction<
  AnalyticsActionPayload,
  AnalyticsAuthActionType
>(AnalyticsAuthActionType.AUTHENTICATED_ACTION);

const unauthenticated = createAction<
  AnalyticsActionPayload,
  AnalyticsAuthActionType
>(AnalyticsAuthActionType.UNAUTHENTICATED_ACTION);

export const analyticsAuthActions = {
  authenticated,
  unauthenticated
};

const isAuthLoginFailedEvent = (
  properties?: object
): properties is AuthEventLoginFailedPayload =>
  properties && "error" in properties && "user" in properties ? true : false;

const processProperties = (
  properties?: AuthEventLoginFailedPayload | object
): object | undefined => {
  if (isAuthLoginFailedEvent(properties)) {
    const {
      error: { code: errorCode },
      user: { email }
    } = properties;
    return {
      errorCode,
      email
    };
  }
  return properties;
};

export const authEventMap: AnyEventMap = {
  [AnalyticsAuthActionType.AUTHENTICATED_ACTION]: (
    action: ReturnType<typeof authenticated>,
    state: OnlineAccountWrapperApplicationState
  ): AnalyticsEvent => {
    const { actionName, analyticsActions, type, properties } = action.payload;
    const {
      user: { firstName, lastName, email, role, partnerName }
    } = properties as unknown as AuthEventLoginSuccessfulPayload;
    return {
      actionName: getActionName(actionName, type),
      analyticsActions: analyticsActions || ["TrackEvent"],
      actionTime: new Date(),
      user: { email, firstName, lastName },
      distinctId: state.analyticsUser.flow,
      properties: {
        role,
        partnerName,
        ...state.analyticsUser
      }
    };
  },
  [AnalyticsAuthActionType.UNAUTHENTICATED_ACTION]: (
    action: ReturnType<typeof unauthenticated>,
    state: OnlineAccountWrapperApplicationState
  ): AnalyticsEvent => {
    const { actionName, analyticsActions, type, properties } = action.payload;

    return {
      actionName: getActionName(actionName, type),
      analyticsActions: analyticsActions || ["TrackEvent"],
      actionTime: new Date(),
      distinctId: state.analyticsUser.flow,
      properties: {
        ...processProperties(properties),
        ...state.analyticsUser
      }
    };
  }
};

import React from "react";
import { Grid, Link, Typography } from "@material-ui/core";
import { AuthCardWrapper } from "@components/Auth";
import { LoadingButton } from "@pie/components";
import { ReturnToLoginLink } from "@components/Auth/LinkText/PreDefinedLinks/ReturnToLoginLink";
import { CustomerCarePhoneNumber } from "@utils/constants";
import { PublicRoutes } from "@pie/online-account-externals";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";

export const headerText = "Your account creation link has expired";
export const expiredLinkText =
  "This link has expired for security reasons. Please create an account below.";
export const createButtonText = "Create an account";

export const RegistrationLinkInvalid: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <AuthCardWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h2" color="textPrimary" align="center">
            {headerText}
          </Typography>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Typography
            variant="body1"
            paragraph
            style={{
              wordBreak: "normal"
            }}
          >
            {expiredLinkText}
          </Typography>
          <Typography>
            For help, contact us at{" "}
            <Link href={`tel:${CustomerCarePhoneNumber}`}>
              {CustomerCarePhoneNumber}
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <LoadingButton
              loading={false}
              type="button"
              variant="contained"
              color="primary"
              onClick={() => dispatch(push(PublicRoutes.CREATE_ACCOUNT))}
            >
              {createButtonText}
            </LoadingButton>
          </Grid>
          <Grid item>
            <ReturnToLoginLink />
          </Grid>
        </Grid>
      </Grid>
    </AuthCardWrapper>
  );
};

import { call, put } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { serviceHealthCheckApi } from "@api/serviceHealthCheck";
import { healthCheckActions } from "@stores/healthCheck";

export function* serviceHealthCheckSaga(): SagaIterator {
  try {
    const res = yield call(serviceHealthCheckApi);
    return yield put(healthCheckActions.serviceHealthCheckCompleted(res.ok));
  } catch {
    return yield put(healthCheckActions.serviceHealthCheckCompleted(false));
  }
}

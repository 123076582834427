import { makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: "block"
  },
  typography: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

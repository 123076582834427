import { getActionName } from "../utils";
import { accountActions } from "../../account";

import {
  AnyEventMap,
  convertToAnalyticsEvent
} from "@pie/online-account-externals";

export const accountEventMap: AnyEventMap = {
  [accountActions.createAccountFailed.toString()]: ({ type }, state) =>
    convertToAnalyticsEvent(state, { actionName: getActionName(type) }),
  [accountActions.createAccountSuccessful.toString()]: ({ type }, state) =>
    convertToAnalyticsEvent(state, { actionName: getActionName(type) }),
  [accountActions.createPasswordAction.toString()]: ({ type }, state) =>
    convertToAnalyticsEvent(state, { actionName: getActionName(type) }),
  [accountActions.forgotPasswordAction.toString()]: ({ type }, state) =>
    convertToAnalyticsEvent(state, { actionName: getActionName(type) }),
  [accountActions.getUserInfoSuccessful.toString()]: ({ type }, state) =>
    convertToAnalyticsEvent(state, { actionName: getActionName(type) }),
  [accountActions.sendRegistrationEmailAction.toString()]: ({ type }, state) =>
    convertToAnalyticsEvent(state, { actionName: getActionName(type) }),
  [accountActions.sendRegistrationEmailSuccessful.toString()]: (
    { type },
    state
  ) => convertToAnalyticsEvent(state, { actionName: getActionName(type) }),
  [accountActions.sendRegistrationEmailFailed.toString()]: ({ type }, state) =>
    convertToAnalyticsEvent(state, { actionName: getActionName(type) })
};

import React, { useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { Grid, Typography, Link, IconButton } from "@material-ui/core";
import { getAuthContext, useIsMobile } from "@pie/components";

import { useTopBarStyles } from "./styles";
import { PublicRoutes } from "@pie/online-account-externals";
import logo from "../../logo.svg";
import { Link as RouterLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/pro-regular-svg-icons/faSignOut";
import { layoutActions } from "@stores/layout";
import { faBars } from "@fortawesome/pro-regular-svg-icons/faBars";
import { leaveModalActions } from "@stores/leaveModal";

export const TopBar: React.FC = () => {
  const { topBar, link, topBarExitToApp, image, logoContainer } =
    useTopBarStyles();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { isAuthenticated } = useContext(getAuthContext());

  const handleLogout = (): void => {
    dispatch(leaveModalActions.showModal());
  };

  const MenuButton = useCallback(() => {
    return (
      <Grid item>
        <IconButton
          onClick={() => dispatch(layoutActions.toggleSideMenu(true))}
        >
          <FontAwesomeIcon icon={faBars} size={"lg"} />
        </IconButton>
      </Grid>
    );
  }, []);

  const SignOutButton = useCallback(() => {
    return (
      <Grid item>
        <Grid container direction="row">
          <Typography component="div" variant="body1">
            <Link className={link} onClick={handleLogout} color="inherit">
              <FontAwesomeIcon
                icon={faSignOut}
                size={"lg"}
                className={topBarExitToApp}
              />
              Sign out
            </Link>
          </Typography>
        </Grid>
      </Grid>
    );
  }, [isAuthenticated, faSignOut, topBarExitToApp, handleLogout]);

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={topBar}
    >
      <Grid item className={logoContainer}>
        <Link component={RouterLink} to={PublicRoutes.SIGN_IN}>
          <img className={image} src={logo} alt="Pie Insurance" />
        </Link>
      </Grid>
      {isAuthenticated && (isMobile ? <MenuButton /> : <SignOutButton />)}
    </Grid>
  );
};

import React, { useCallback } from "react";
import {
  Typography,
  Link,
  Card,
  CardHeader,
  CardContent
} from "@material-ui/core";
import { useStyles } from "./styles";
import { ExternalLinks, ReportClaimPhoneNumber } from "../../utils/constants";

export enum ReportAccidentCardLabels {
  TITLE = "Report an accident"
}

export const ReportAccidentCard: React.FC = () => {
  const styles = useStyles();
  const { WORKPLACE_INJURY_LINK } = ExternalLinks;

  const Content = useCallback(
    () => (
      <>
        <Typography>Available 24/7</Typography>
        <Link href={`tel:${ReportClaimPhoneNumber}`}>
          {ReportClaimPhoneNumber}
        </Link>
        <Link
          href={WORKPLACE_INJURY_LINK}
          target="_blank"
          rel="noopener"
          underline="always"
          className={styles.link}
        >
          Learn more
        </Link>
      </>
    ),
    [ReportClaimPhoneNumber, WORKPLACE_INJURY_LINK]
  );

  return (
    <Card variant="outlined">
      <CardHeader
        title={ReportAccidentCardLabels.TITLE}
        titleTypographyProps={{ className: styles.typography }}
      />
      <CardContent>
        <Content />
      </CardContent>
    </Card>
  );
};

import { AuthRoutes } from "@pie/online-account-externals";
import { MicroAppInterface } from "./micro-apps/MicroApp";
import config from "./config";

export default (data?: object) =>
  [
    {
      path: AuthRoutes.POLICY,
      name: "accountpolicydetailsui",
      url: `${config["accountpolicydetailsui"]}/microui/accountpolicydetailsui`,
      disableSandbox: true,
      data: data
    },
    {
      path: AuthRoutes.DOCUMENTS,
      name: "accountdocumentsui",
      url: `${config["accountdocumentsui"]}/microui/accountdocumentsui`,
      disableSandbox: true,
      data: data
    },
    {
      path: AuthRoutes.HOME,
      name: "accountoverviewui",
      url: `${config["accountoverviewui"]}/microui/accountoverviewui`,
      disableSandbox: true,
      data: data,
      exactMatch: true
    },
    {
      path: AuthRoutes.PAYMENTS,
      name: "accountbillingdetailsui",
      url: `${config["accountbillingdetailsui"]}/microui/accountbillingdetailsui`,
      disableSandbox: true,
      data: data
    }
  ] as MicroAppInterface[];

import { makeStyles, Theme } from "@material-ui/core";

export const useBodyStyles = makeStyles((theme: Theme) => ({
  container: {
    "& p": {
      marginBottom: theme.spacing(2),
      textAlign: "left"
    }
  }
}));

import React from "react";
import { Link, LinkProps } from "@material-ui/core";
import { useLinkStyles } from "./styles";

export type LinkComponentProps = LinkProps & {
  icon: React.ReactNode;
};

export const LinkComponent: React.FC<LinkComponentProps> = ({
  icon,
  children,
  href,
  underline = "none",
  ...restProps
}) => {
  const styles = useLinkStyles();
  return (
    <Link
      target="_blank"
      rel="noopener"
      className={styles.componentStyle}
      {...{ href, underline, ...restProps }}
    >
      <div className={styles.icon}>{icon}</div>
      <span className={styles.labelStyle}>{children}</span>
    </Link>
  );
};

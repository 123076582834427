import { fetchOptions, ApiRoute } from "./constants";

export interface PaymentSetupApiCallArgs {
  emailToken: string;
}

export const paymentSetupLandingApi = ({
  emailToken
}: PaymentSetupApiCallArgs): Promise<Response> => {
  return fetch(`${ApiRoute.PAYMENT_SETUP_BASE_ROUTE}?token=${emailToken}`, {
    ...fetchOptions,
    method: "GET"
  });
};

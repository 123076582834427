import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { Grid, Typography } from "@material-ui/core";
import {
  PaymentSetup as CorePaymentSetup,
  Loader,
  PaymentAcknowledgement,
  PaymentPayload,
  BankPayload,
  CardPayload,
  BillingType
} from "@pie/components";
import { paymentActions } from "@stores/paymentSetup";
import { usePaymentSetupStyles } from "./styles";
import { selectLoadingState } from "@stores/loadingState";
import {
  PublicRoutes,
  selectPayment,
  AchDetailData
} from "@pie/online-account-externals";
import { PaymentDetailsCard } from "../../components";
import { accountActions } from "@stores/account";

export const paymentFormTestId = "payment-form";
export const paymentOptionsTitle = "Select Payment Method";
export const submitButtonText = "Authorize";
export const documentAcknowledgementTitle = "Document Acknowledgement";
export const roleOptions = [
  { value: "insured", label: "I am the policyholder." },
  {
    value: "agent",
    label: "I am the agent or represent the policyholder"
  }
];

export const PaymentSetup: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = queryString.parse(location.search) || "";
  const {
    businessName,
    policyNumber,
    paymentPlan,
    depositAmount,
    effectiveDate,
    expirationDate,
    isIssued,
    hasError,
    errorMessage,
    nextStepMessage
  } = useSelector(selectPayment);
  const loading = useSelector(selectLoadingState);
  const [showError, setShowError] = useState(false);

  const { mainContainer, formStyle, boldText } = usePaymentSetupStyles();

  useEffect(() => {
    const isIssued = location.pathname === PublicRoutes.PAYMENT_SETUP_CUSTOMER;
    dispatch(accountActions.saveEmailToken(token));
    dispatch(paymentActions.getPolicyInfoAction({ isIssued }));
  }, [dispatch, location.pathname, token]);

  useEffect(() => {
    setShowError(hasError);
  }, [hasError]);

  const dispatchACHSetup = (data: BankPayload): void => {
    const achData: AchDetailData = {
      accountNumber: data.accountNumber,
      routingNumber: data.routingNumber,
      accountHolderType: data.accountType,
      accountHolderName: `${data.firstName} ${data.lastName}`,
      country: "US",
      currency: "usd"
    };
    dispatch(paymentActions.setACHPayment(achData as AchDetailData));
  };

  const dispatchCardSetup = async (data: CardPayload) => {
    const sourceResult = data.sourceResult;
    const hasStripeError = sourceResult?.error;
    if (sourceResult?.token && !hasStripeError) {
      dispatch(paymentActions.setCardPayment(sourceResult.token as string));
    }
  };

  const onStartSubmit = () => {
    dispatch(paymentActions.resetPaymentSetupError());
  };

  const onPaymentTypeSelectionChange = () => {
    dispatch(paymentActions.resetPaymentSetupError());
  };

  const onSubmit = (data: PaymentPayload, e: any) => {
    e.preventDefault();
    if (data.billingType === BillingType.BANK) {
      dispatchACHSetup(data as BankPayload);
    }
    if (data.billingType === BillingType.CARD) {
      dispatchCardSetup(data as CardPayload);
    }
  };

  return businessName ? (
    <Grid container justify="space-around" className={mainContainer}>
      <Grid item md={3} sm={12}>
        <PaymentDetailsCard
          {...{
            isIssued,
            businessName,
            policyNumber,
            paymentPlan,
            depositAmount,
            effectiveDate,
            expirationDate
          }}
        />
      </Grid>
      <Grid md={8} sm={12} item className={formStyle}>
        <CorePaymentSetup
          onStartSubmit={onStartSubmit}
          onSubmit={onSubmit}
          onPaymentTypeSelectionChange={onPaymentTypeSelectionChange}
          loading={loading}
          error={showError}
          customError={
            errorMessage ? (
              <Grid>
                <Typography paragraph className={boldText}>
                  {errorMessage}
                </Typography>
                <Typography>{nextStepMessage}</Typography>
              </Grid>
            ) : null
          }
        >
          <PaymentAcknowledgement />
        </CorePaymentSetup>
      </Grid>
    </Grid>
  ) : (
    <Loader />
  );
};

import { Grid, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CallNow } from "../../components/CallNow";
import { LoadingButton } from "@pie/components";
import { paymentActions } from "../../store/paymentSetup";
import { selectLoadingState } from "../../store/loadingState";
import { selectPayment } from "@pie/online-account-externals";
import { useCommonStyle } from "../../components/commonStyles";
import { useLinkExpiredStyle } from "./styles";

export const PaymentLinkExpired: React.FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectLoadingState);
  const { fontWeightMedium } = useCommonStyle();
  const { containerStyle, buttonStyle } = useLinkExpiredStyle();

  const { emailToken, userEmailAddress, isIssued } = useSelector(selectPayment);
  const handleResendEmail = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      dispatch(
        paymentActions.resendPaymentSetupEmail({
          token: emailToken as string,
          email: userEmailAddress as string,
          isIssued
        })
      );
    },
    [emailToken, userEmailAddress, isIssued]
  );
  const Body = useCallback(
    () =>
      isIssued ? (
        <form onSubmit={handleResendEmail}>
          <Typography paragraph variant="subtitle2">
            Please request a new link to the following email:
          </Typography>
          <Typography variant="subtitle2">{userEmailAddress}</Typography>
          <LoadingButton
            loading={isLoading}
            className={buttonStyle}
            variant="contained"
            color="primary"
            type="submit"
          >
            Resend email
          </LoadingButton>
        </form>
      ) : (
        <Typography paragraph variant="subtitle2">
          Please contact us or your agent for assistance on payment.
        </Typography>
      ),
    [isIssued, handleResendEmail, userEmailAddress, isLoading]
  );
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item className={containerStyle}>
        <Typography paragraph variant="h1" className={fontWeightMedium}>
          Your email link has expired
        </Typography>
        <Typography paragraph variant="subtitle2">
          This link has expired for security reasons.
        </Typography>
        <Body />
      </Grid>
      <CallNow messageText="Call now" />
    </Grid>
  );
};

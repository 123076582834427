import { Grid, Typography } from "@material-ui/core";
import {
  LoadingButton,
  PasswordInput,
  TextInputInfoLabel,
  useAuth,
  useIsMobile,
  AuthFormStatusType
} from "@pie/components";
import React, { useCallback, useEffect, useState } from "react";
import {
  addErrorSnackbar,
  addSuccessSnackbar,
  PublicRoutes
} from "@pie/online-account-externals";
import { AuthCardWrapper } from "@components/Auth";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { useMount } from "@pie/utils";

import { FormFields } from "../constants";
import { accountActions } from "../../../store/account";
import { useAuthStyles } from "../styles";
import { useResetStyles } from "./style";

export const successfulReset = "Password reset successfully.";
export const resetPasswordHeader = "Reset your password.";
export const resetButtonText = "Reset password";

interface ResetPasswordFormData {
  resetCode: string;
  email: string;
}

export const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const {
    forgotPasswordSubmit,
    loading,
    authFormData,
    authErrors,
    changeAuthFormDisplayed
  } = useAuth();
  const [newPassword, setNewPassword] = useState<string>("");
  const { button } = useAuthStyles();
  const { resetButton } = useResetStyles();
  const isMobile = useIsMobile();

  const handleNewPassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const { value } = e.currentTarget;
    setNewPassword(value);
  };

  const handleSubmit = useCallback(
    (e: React.FormEvent): void => {
      e.preventDefault();
      const resetFormData = authFormData.payload as ResetPasswordFormData;
      if (resetFormData.email && newPassword && resetFormData.resetCode) {
        forgotPasswordSubmit(
          resetFormData.email,
          resetFormData.resetCode,
          newPassword
        );
      }
    },
    [forgotPasswordSubmit, authFormData.payload, newPassword]
  );

  // Only a temporary necessity until we integrate it back into core
  useMount(() => {
    changeAuthFormDisplayed({ formType: AuthFormStatusType.RESET_PASSWORD });
  });

  // This only exists cause we can't tap into the `forgotPasswordSubmit` callbacks
  // Will be fixed when we integrate this into core
  useEffect(() => {
    if (
      !authErrors.length &&
      !loading &&
      authFormData.formType === AuthFormStatusType.LOGIN
    ) {
      dispatch(
        addSuccessSnackbar({
          message: successfulReset
        })
      );
      dispatch(push(PublicRoutes.SIGN_IN));
    } else {
      authErrors.forEach(err => {
        if (err.type == "ExpiredCodeException") {
          dispatch(accountActions.saveResetPasswordEmail(resetFormData.email));
          dispatch(push(PublicRoutes.RESET_EMAIL_LINK_EXPIRED));
        } else if (err.message) {
          dispatch(
            addErrorSnackbar({
              message: err.message
            })
          );
        }
      });
    }
  }, [authErrors, authFormData.formType, dispatch, loading]);

  const resetFormData = authFormData.payload as ResetPasswordFormData;

  return (
    <AuthCardWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h2" color="primary">
            {resetPasswordHeader}
          </Typography>
        </Grid>
        <Grid item>
          <form onSubmit={handleSubmit}>
            <Grid item container direction="column">
              <TextInputInfoLabel
                required
                type={FormFields.EMAIL}
                id={FormFields.EMAIL}
                label="Email"
                name={FormFields.EMAIL}
                value={authFormData.payload?.email || ""}
                disabled
              />
              <PasswordInput
                required
                id={FormFields.NEW_PASSWORD}
                label="New Password"
                name={FormFields.NEW_PASSWORD}
                onChange={handleNewPassword}
              />
            </Grid>
            <Grid
              container
              direction="column"
              className={resetButton}
              alignItems="center"
            >
              <Grid item style={{ width: isMobile ? "100%" : "unset" }}>
                <LoadingButton
                  loading={loading}
                  className={button}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={
                    !resetFormData?.email &&
                    !resetFormData?.resetCode &&
                    !newPassword
                  }
                  fullWidth={isMobile}
                >
                  {resetButtonText}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </AuthCardWrapper>
  );
};

import { makeStyles, Theme } from "@material-ui/core";

export const usePaymentCardStyles = makeStyles((theme: Theme) => ({
  typography: {
    fontWeight: theme.typography.fontWeightMedium
  },
  iconSpacing: {
    marginRight: theme.spacing(1)
  },
  iconColor: { color: theme.palette.secondary.main },
  dataContainer: {
    marginBottom: theme.spacing(1.5)
  },
  dateStyle: {
    margin: theme.spacing(2.5, 0, 5),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1.5, 0)
    }
  },
  encryptionStyle: {
    width: "100%",
    textAlign: "center"
  }
}));

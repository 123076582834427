import { format, parseISO } from "date-fns";
import { isAuthEventLoginFailedPayload } from "./guards";
import { AuthEventLoginFailedPayload } from "@pie/components";

declare global {
  interface DollarifyOptions {
    cents?: boolean;
    negatives?: "parens" | "minus";
  }
}

export const dollarify = (
  num: number | string,
  options?: DollarifyOptions
): string => {
  if (typeof num === "string") {
    num = parseFloat(num);
  }
  const { negatives, cents } = options || {};
  const negative = (dollarString: string): string => {
    if (num >= 0) return dollarString;
    return negatives === "parens" ? `(${dollarString})` : `-${dollarString}`;
  };
  const amount = Math.abs(cents ? num : Math.round(num));
  const dollarFormat = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });
  const currency = dollarFormat.format(amount);
  return cents
    ? negative(currency)
    : negative(currency.substring(0, currency.length - 3));
};

export const formatDate = (date: string): string =>
  format(parseISO(date), "MM/dd/yyyy");

export const replaceSpacesWithDelimiter = (
  input: string | undefined,
  replaceValue = ""
): string => (input && input.replace(/\s+/g, replaceValue)) ?? "";

export const toPrecisionNumber = (num: number, precision = 4): number =>
  Number(num.toPrecision(precision));

export const isAuthEventFailCode = (
  payload: object,
  ...codes: string[]
): payload is AuthEventLoginFailedPayload =>
  isAuthEventLoginFailedPayload(payload) &&
  !!payload.error.code &&
  codes.includes(payload.error.code);

export const getErrorMessageFromResponse = (response: {
  errors?: { [key: string]: string[] };
}) => {
  const errors = Object.values(response?.errors || {});
  const errorMessages = errors.reduce((acc, cur) => acc.concat(cur), []);
  return errorMessages.length > 0 ? (errorMessages[0] as string) : "";
};

export const maskEmail = (email: string) => {
  if (email) {
    email = `${email.substring(0, 1)}****${email.substring(
      email.indexOf("@") - 1
    )}`;
    return email;
  }
};

export const nextStepMessage =
  "Your payment method cannot be updated. Please contact your card issuer for more information.";

export const stripeErrorDeclineCodes = [
  {
    declineCode: "incorrect_number",
    description: "The card number is incorrect.",
    nextStepMessage: "Please enter the correct card number."
  },
  {
    declineCode: "insufficient_funds",
    description: "The card has insufficient funds.",
    nextStepMessage
  },
  {
    declineCode: "invalid_number",
    description: "The card number is incorrect.",
    nextStepMessage: "Please enter the correct card number."
  },
  {
    declineCode: "incorrect_cvc",
    description: "The CVC number is incorrect.",
    nextStepMessage: "Please enter the correct CVC number."
  },
  {
    declineCode: "invalid_cvc",
    description: "The CVC number is incorrect.",
    nextStepMessage: "Please enter the correct CVC number."
  },
  {
    declineCode: "invalid_expiry_month",
    description: "The expiration month is invalid.",
    nextStepMessage: "Please enter the correct expiration date."
  },
  {
    declineCode: "invalid_expiry_year",
    description: "The expiration year is invalid.",
    nextStepMessage: "Please enter the correct expiration date."
  },
  {
    declineCode: "withdrawal_count_limit_exceeded",
    description:
      "The customer has exceeded the balance or credit limit available on their card.",
    nextStepMessage: "Please use another payment method."
  },
  {
    declineCode: "expired_card",
    description: "The card has expired.",
    nextStepMessage: "Please use a different payment method."
  },
  {
    declineCode: "card_velocity_exceeded",
    description:
      "The customer has exceeded the balance or credit limit available on their card.",
    nextStepMessage
  },
  {
    declineCode: "currency_not_supported",
    description: "The card does not support the specified currency.",
    nextStepMessage
  },
  {
    declineCode: "invalid_account",
    description: "The card, or account the card is connected to, is invalid.",
    nextStepMessage
  },
  {
    declineCode: "new_account_information_available",
    description: "The card, or account the card is connected to, is invalid.",
    nextStepMessage
  },
  {
    declineCode: "pickup_card",
    description: "This card cannot be used at this time.",
    nextStepMessage
  },
  {
    declineCode: "restricted_card",
    description: "This card cannot be used at this time.",
    nextStepMessage
  },
  {
    declineCode: "stolen_card",
    description: "This card cannot be used at this time.",
    nextStepMessage
  },
  {
    declineCode: "lost_card",
    description: "This card cannot be used at this time.",
    nextStepMessage
  },
  {
    declineCode: "testmode_decline",
    description: "A Stripe test card number was used.",
    nextStepMessage
  },
  {
    declineCode: "generic_decline",
    description: "The card is declined. ",
    nextStepMessage
  },
  {
    declineCode: "processing_error",
    description:
      "An error occurred while processing your card. Try again in a little bit.",
    nextStepMessage: ""
  }
];

export const initMouseFlowFeedbackSurvey = (surveyId?: string): void => {
  if (!surveyId) return;

  window._mfq = window._mfq || [];
  window._mfq.push(["activateFeedback", surveyId]);
};

import {
  AnalyticsEvent,
  AnalyticsInternalProperty,
  ANALYTICS_APPLICATION_NAME
} from "@pie/online-account-externals";
import { ApiRoute, fetchOptions } from "./constants";

export const analyticsLogger = (payload: AnalyticsEvent): Promise<Response> =>
  fetch(ApiRoute.ANALYTICS, {
    ...fetchOptions,
    method: "POST",
    body: JSON.stringify({
      ...payload,
      properties: {
        ...payload.properties,
        [AnalyticsInternalProperty.SOURCE]: ANALYTICS_APPLICATION_NAME
      }
    })
  });

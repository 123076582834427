import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { CheckCancel } from "@pie/components";

import { CallNow } from "../../components/CallNow";
import { useCommonStyle } from "../../components/commonStyles";
import { useLinkExpiredStyle } from "./styles";
import { selectPayment } from "@pie/online-account-externals";

export const emailSentText = "Email sent";

export const PaymentEmailSent: React.FC = () => {
  const { fontWeightMedium } = useCommonStyle();
  const { containerStyle } = useLinkExpiredStyle();

  const { userEmailAddress } = useSelector(selectPayment);

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item className={containerStyle}>
        <Typography paragraph variant="h1" className={fontWeightMedium}>
          <CheckCancel checked fontSize="default" />
          {emailSentText}
        </Typography>
        <Typography paragraph variant="h2">
          Follow the instructions in the email sent to:
        </Typography>
        <Typography paragraph variant="h2">
          {userEmailAddress}
        </Typography>
      </Grid>
      <CallNow messageText="Call now" />
    </Grid>
  );
};

import { createSlice } from "@reduxjs/toolkit";

import {
  LeaveModalState,
  OnlineAccountWrapperStateKey
} from "@pie/online-account-externals";

export const initialState: LeaveModalState = {
  isOpen: false
};

const leaveModalSlice = createSlice({
  name: OnlineAccountWrapperStateKey.LEAVE_MODAL,
  initialState,
  reducers: {
    hideModal(state: LeaveModalState): LeaveModalState {
      return {
        ...state,
        isOpen: false
      };
    },
    showModal(state: LeaveModalState): LeaveModalState {
      return {
        ...state,
        isOpen: true
      };
    }
  }
});

export const leaveModalReducer = leaveModalSlice.reducer;
export const leaveModalActions = leaveModalSlice.actions;

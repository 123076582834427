import {
  AnalyticsEvent,
  convertToAnalyticsEvent,
  OnlineAccountWrapperApplicationState,
  OnlineAccountWrapperStateKey
} from "@pie/online-account-externals";
import { termsAndConditionsActions } from "@stores/termsAndConditions";
import { Action } from "redux";

export type TermsAndConditionsActionTypes =
  `${OnlineAccountWrapperStateKey.TERMS_AND_CONDITIONS}/${keyof typeof termsAndConditionsActions}`;

export const TermsAndConditionsEventNames: {
  [K in Exclude<
    TermsAndConditionsActionTypes,
    | "termsAndConditions/successfulResponse"
    | "termsAndConditions/failedResponse"
  >]: string;
} = {
  "termsAndConditions/acceptTermsAndConditions":
    "Terms and Conditions were accepted",
  "termsAndConditions/denyTermsAndConditions":
    "Terms and Conditions were denied",
  "termsAndConditions/closeModal": "Terms and Conditions modal was closed"
};

export type TermsAndConditionsEventsMap = {
  [K in keyof typeof TermsAndConditionsEventNames]: () => AnalyticsEvent;
};
export const genericEventMap: TermsAndConditionsEventsMap = Object.entries(
  TermsAndConditionsEventNames
).reduce(
  (eventsMap, [actionType, actionName]) => ({
    ...eventsMap,
    [actionType]: (_: Action, state: OnlineAccountWrapperApplicationState) =>
      convertToAnalyticsEvent(state, { actionName })
  }),
  {} as TermsAndConditionsEventsMap
);

export const termsAndConditionsEventsMap = {
  ...genericEventMap
};

import React, { useCallback } from "react";
import {
  Typography,
  Link,
  Card,
  CardHeader,
  CardContent
} from "@material-ui/core";
import { useContactCardStyles } from "./styles";
import {
  CustomerCarePhoneNumber,
  CustomerCareEmail
} from "../../utils/constants";

export enum ContactCardLabels {
  TITLE = "Need to make a policy change?"
}

export const ContactCard: React.FC = () => {
  const styles = useContactCardStyles();

  const Content = useCallback(
    () => (
      <>
        <Typography>M-F 9am-9pm ET </Typography>
        <Link href={`tel:${CustomerCarePhoneNumber}`}>
          {CustomerCarePhoneNumber}
        </Link>
        <Link href={`mailto:${CustomerCareEmail}`} className={styles.link}>
          {CustomerCareEmail}
        </Link>
        <Typography className={styles.typography} component="span">
          Policy changes may take up to 24 hours to appear.
        </Typography>
      </>
    ),
    [CustomerCarePhoneNumber, CustomerCareEmail]
  );

  return (
    <Card variant="outlined">
      <CardHeader
        title={ContactCardLabels.TITLE}
        titleTypographyProps={{ className: styles.typography }}
      />
      <CardContent>
        <Content />
      </CardContent>
    </Card>
  );
};

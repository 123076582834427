import React from "react";
import { AppStoreType, createStore, history } from "@stores/createStore";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { ConnectedRouter } from "connected-react-router";
import { Typography } from "@material-ui/core";
import { useAppStyles } from "@components/App/styles";
import { theme } from "../../theme";
import { TopBar } from "@components/TopBar";
import { Footer } from "@components/Footer";
import { TerminalPage } from "@pages/TerminalPage/TerminalPage";

class ErrorBoundaryProvider extends React.Component<
  { rootClassName: any },
  { error: any }
> {
  store: AppStoreType;
  isComponentMounted = false;

  constructor(props: any) {
    super(props);
    this.showError = this.showError.bind(this);
    this.state = { error: null };
    this.store = createStore(this.showError);
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  componentDidCatch(error: any) {
    this.showError(error);
  }

  showError(error: any) {
    if (this.isComponentMounted) {
      this.setState({ error });
    } else {
      // eslint-disable-next-line
      this.state = { error };
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Provider {...this.props} store={this.store}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <Typography component="div" className={this.props.rootClassName}>
                <TopBar />
                <TerminalPage
                  pageType="error"
                  mainText="Sorry, we are experiencing a technical issue."
                  subText="Please contact us for help."
                  callBoxText="Call now"
                />
                <Footer />
              </Typography>
            </ThemeProvider>
          </ConnectedRouter>
        </Provider>
      );
    }
    return <Provider {...this.props} store={this.store} />;
  }
}

// eslint-disable-next-line react/display-name
const withHooksHOC = (Component: any) => (props: any) => {
  const { root } = useAppStyles();
  return <Component rootClassName={root} {...props} />;
};

export default withHooksHOC(ErrorBoundaryProvider);

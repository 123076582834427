const origin = "http://localhost:3000";

let config = {
  accountoverviewui: origin,
  accountpolicydetailsui: origin,
  accountdocumentsui: origin,
  accountbillingdetailsui: origin
};

export type AppOptions = keyof typeof config;
export const appNames = Object.keys(config) as AppOptions[];

const isEnvPro = process.env.NODE_ENV === "production";
if (isEnvPro) {
  const locationOrigin = `${location.origin}`;
  config = {
    accountoverviewui: locationOrigin,
    accountpolicydetailsui: locationOrigin,
    accountdocumentsui: locationOrigin,
    accountbillingdetailsui: locationOrigin
  };
}

export default config;

import { ApiRoute, fetchOptions } from "./constants";
import { CreatePasswordPayload } from "../store/sagas/createPassword/createPasswordSaga";

export const createPasswordApi = (
  payload: CreatePasswordPayload
): Promise<Response> => {
  return fetch(ApiRoute.BASE_USER_ROUTE, {
    ...fetchOptions,
    method: "POST",
    body: JSON.stringify(payload)
  });
};

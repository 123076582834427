import { Grid, Link, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { AuthCardWrapper } from "@components/Auth";
import { useAuthStyles } from "../styles";
import { AuthFormStatusType, LoadingButton, useAuth } from "@pie/components";
import { CustomerCarePhoneNumber } from "@utils/constants";
import { useLocation } from "react-router-dom";
import { useMount } from "@pie/utils";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "@pie/online-account-externals";
import { ForgotPasswordSentData } from "@pie/components/dist/auth/ForgotPasswordSent/ForgotPasswordSent";
import { onlineAccountForgotPasswordRequest } from "../api";
import { EmailFailed } from "../common";
import { ReturnToLoginLink } from "@components/Auth/LinkText/PreDefinedLinks/ReturnToLoginLink";

export const headerText =
  "Due to a security upgrade you will need to reset your password.";
export const securityUpgradeBodyText1 =
  'If you don\'t receive the email check your spam folder or send the email again using the "Send again" button below.';
export const securityUpgradeBodyText2 =
  "If you believe the email associated with this policy is incorrect, contact us at ";
export const sendAgainText = "Send again";

export const SecurityUpgrade: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<string>();
  const { clearAuthErrors, changeAuthFormDisplayed, loading } = useAuth();
  const { button } = useAuthStyles();

  const forgotPassword = useCallback(
    (email: string): void => {
      clearAuthErrors();
      onlineAccountForgotPasswordRequest(email).then(() => {
        changeAuthFormDisplayed({
          formType: AuthFormStatusType.FORGOT_EMAIL_SENT,
          payload: { email } as ForgotPasswordSentData
        });
      });
    },
    [changeAuthFormDisplayed, clearAuthErrors]
  );

  const handleSendAgain = useCallback(() => {
    try {
      forgotPassword(location.state);
      dispatch(
        enqueueSnackbar({
          message:
            "Email has been sent. Please check your inbox or spam folder.",
          options: {
            variant: "success"
          }
        })
      );
    } catch (e) {
      dispatch(
        enqueueSnackbar({
          message: <EmailFailed />,
          options: {
            variant: "error"
          }
        })
      );
    }
  }, [location.state, forgotPassword, dispatch]);

  useMount(() => {
    handleSendAgain();
  });

  return (
    <AuthCardWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h6" color="textPrimary">
            {headerText}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{securityUpgradeBodyText1}</Typography>
          <br />
          <Typography variant="body1">
            {securityUpgradeBodyText2}{" "}
            <Link href={`tel:${CustomerCarePhoneNumber}`}>
              {CustomerCarePhoneNumber}
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <LoadingButton
              loading={loading}
              className={button}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSendAgain}
            >
              {sendAgainText}
            </LoadingButton>
          </Grid>
          <Grid item>
            <ReturnToLoginLink />
          </Grid>
        </Grid>
      </Grid>
    </AuthCardWrapper>
  );
};

import { PayloadAction } from "@reduxjs/toolkit";
import { coiRequest } from "@api/coiRequest";
import {
  call,
  COIRequestPayload,
  CoiType
} from "@pie/online-account-externals";
import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import { PublicRoutes } from "@pie/online-account-externals";
import { selectAuthUserIdToken } from "@pie/online-account-externals";
import {
  coiRequestFinished,
  coiRequestNotAllowed
} from "@pie/online-account-externals";

export const coiSuccessMessage = "Certificate successfully requested";

export function* fetchCOI(
  action: PayloadAction<COIRequestPayload>
): SagaIterator {
  const request: COIRequestPayload = action.payload;
  const idToken = yield select(selectAuthUserIdToken);
  if (request.policyStatus.toLowerCase() !== "active") {
    yield put(push(PublicRoutes.CONTACT_US));
    yield put(coiRequestNotAllowed());
  } else {
    const response: Response = yield call(coiRequest, idToken, action.payload);
    if (response.ok) {
      yield put(
        coiRequestFinished({
          success: true,
          coiType: CoiType.CUSTOM
        })
      );
    } else {
      yield put(
        coiRequestFinished({
          success: false,
          coiType: CoiType.CUSTOM
        })
      );
    }
  }
}

import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { loadingActions } from "../../loadingState";
import {
  call,
  InternalBankSetupData,
  InternalCardSetupData,
  InternalPaymentData,
  selectAuthUserIdToken,
  setInternalPaymentFailed,
  setInternalPaymentSuccess
} from "@pie/online-account-externals";
import { internalACHSetupApi } from "../../../api/internalPaymentSetupApi/internalACHSetupApi";
import { internalCardSetupApi } from "../../../api/internalPaymentSetupApi/internalCardSetupApi";
import { stripeErrorDeclineCodes } from "@utils/helpers";
import { BillingType } from "@pie/components";
import { InternalPaymentSuccessMessage } from "@utils/constants";

const callBankSetup = (idToken: string, data: InternalBankSetupData) => {
  const { policyNumber, ...bankData } = data;

  return call(internalACHSetupApi, {
    ownerData: JSON.stringify(bankData),
    policyNumber,
    idToken
  });
};

const callCardSetup = (idToken: string, data: InternalCardSetupData) => {
  const { policyNumber, ...cardData } = data;

  return call(internalCardSetupApi, {
    stripeSourceToken: cardData.stripeSourceToken,
    policyNumber,
    idToken
  });
};

export function* internalPaymentSetupSaga(
  action: PayloadAction<InternalPaymentData>
): SagaIterator {
  const { billingType, pathname, ...rest } = action.payload;

  try {
    const idToken = yield select(selectAuthUserIdToken);

    const payment =
      billingType === BillingType.BANK
        ? yield callBankSetup(idToken, rest as InternalBankSetupData)
        : yield callCardSetup(idToken, rest as InternalCardSetupData);

    if (payment.ok) {
      return yield put(
        setInternalPaymentSuccess({
          message: InternalPaymentSuccessMessage,
          pathname
        })
      );
    } else if (!payment.ok && payment.status < 500) {
      /*eslint-disable prefer-const*/
      let {
        errors: { errorDeclineCode, errorType, errorMessage }
      } = yield payment.json();

      errorDeclineCode = errorDeclineCode[0]
        ? errorDeclineCode[0]
        : errorType[0];

      const mappedError = stripeErrorDeclineCodes.find(
        error => error.declineCode === errorDeclineCode
      );

      return yield put(
        setInternalPaymentFailed({
          message: mappedError?.description || errorMessage,
          nextStepMessage: mappedError?.nextStepMessage,
          pathname
        })
      );
    } else {
      throw payment;
    }
  } catch {
    yield put(loadingActions.setLoading(false));
    return yield put(setInternalPaymentFailed({ message: "", pathname }));
  }
}

import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import {
  acceptTermsAndConditionsApi,
  getTermsAndConditionsAcceptedApi
} from "../../../api/termsAndConditionsApi";
import {
  call,
  HttpStatusCodes,
  selectAuthUserIdToken
} from "@pie/online-account-externals";
import { termsAndConditionsActions } from "@stores/termsAndConditions";

export function* acceptTermsAndConditionsSaga(): SagaIterator {
  try {
    const idToken = yield select(selectAuthUserIdToken);
    return yield call(acceptTermsAndConditionsApi, idToken);
  } catch (ex) {
    return ex;
  }
}

export function* getTermsAndConditionsAcceptedSaga(): SagaIterator {
  try {
    const idToken = yield select(selectAuthUserIdToken);
    const res = yield call(getTermsAndConditionsAcceptedApi, idToken);
    if (res.ok) {
      const accepted: boolean = yield res.json();
      return yield put(termsAndConditionsActions.successfulResponse(accepted));
    } else if (res.status === HttpStatusCodes.NotFound) {
      return yield put(termsAndConditionsActions.successfulResponse(false));
    }

    return yield put(termsAndConditionsActions.failedResponse());
  } catch {
    return yield put(termsAndConditionsActions.failedResponse());
  }
}

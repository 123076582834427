import { ApiRoute, fetchOptions } from "./constants";

export interface CardSetupActionPayload {
  stripeSourceToken: string;
  emailToken: string;
}

export const cardSetupApi = (
  payload: CardSetupActionPayload
): Promise<Response> =>
  fetch(ApiRoute.CARD_PAYMENT_SETUP, {
    ...fetchOptions,
    method: "POST",
    body: JSON.stringify(payload)
  });

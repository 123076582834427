import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import {
  CustomerCareEmail,
  CustomerCarePhoneNumber
} from "../../../utils/constants";
import { useContactBlock, useFooterStyles } from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserHeadset } from "@fortawesome/pro-solid-svg-icons/faUserHeadset";

export interface CustomerCareProps {
  noIcon?: boolean;
}

export const CustomerCare: React.FC<CustomerCareProps> = ({ noIcon }) => {
  const { blockStyle } = useContactBlock();
  const { iconStyle } = useFooterStyles();
  return (
    <Grid container>
      {noIcon ? null : (
        <Grid item>
          <FontAwesomeIcon
            icon={faUserHeadset}
            size="lg"
            className={iconStyle}
          />
        </Grid>
      )}
      <Grid item className={blockStyle}>
        <Typography variant="body2" gutterBottom>
          Contact us
        </Typography>
        <Typography display="block" variant="caption">
          M-F 9am-9pm ET
        </Typography>
        <Typography variant="caption">
          <Link href={`tel:${CustomerCarePhoneNumber}`}>
            {CustomerCarePhoneNumber}
          </Link>
        </Typography>
        <Typography display="block" variant="caption">
          <Link href={`mailto:${CustomerCareEmail}`} underline="always">
            {CustomerCareEmail}
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

import { all, takeLatest } from "redux-saga/effects";

import { SagaIterator } from "redux-saga";
import { accountActions } from "../account";
import { bankPaymentSetupSaga } from "../sagas/bankPaymentSetupSaga/bankPaymentSetupSaga";
import { cardPaymentSetupSaga } from "../sagas/cardPaymentSetupSaga/cardPaymentSetupSaga";
import { checkServiceHealth } from "@stores/healthCheck";
import { createPasswordSaga } from "../sagas/createPassword/createPasswordSaga";
import { fetchCOI } from "@stores/sagas/coiRequest/sagas";
import { forgotPasswordSaga } from "../sagas/forgotPasswordSaga/forgotPasswordSaga";
import { getUserInfoByTokenSaga } from "../sagas/getUserInfo/getUserInfoByTokenSaga";
import { internalPaymentSetupSaga } from "../sagas/internalPaymentSetup/internalPaymentSetupSaga";
import { paymentActions } from "../paymentSetup";
import { paymentSetupLandingSaga } from "../sagas/paymentSetupLandingSaga/paymentSetupLandingSaga";
import {
  createInstantCoiAction,
  requestCOI,
  setInternalPayment
} from "@pie/online-account-externals";
import { resendPaymentSetupEmailSaga } from "../sagas/resendPaymentSetupEmailSaga/resendPaymentSetupEmailSaga";
import { sendRegistrationEmailSaga } from "../sagas/sendRegistrationEmail/sendRegistrationEmailSaga";
import { serviceHealthCheckSaga } from "@stores/sagas/serviceHealthCheckSaga/serviceHealthCheckSaga";
import { createInstantCOISaga } from "../sagas/instantCoi/instantCoiSaga";
import { authUserActions } from "@stores/authUser";
import {
  acceptTermsAndConditionsSaga,
  getTermsAndConditionsAcceptedSaga
} from "@stores/sagas/termsAndConditionsSaga/termsAndConditionsSaga";
import { termsAndConditionsActions } from "@stores/termsAndConditions";
import { auditIntakeActions } from "@stores/auditIntake";
import { getAuditIntakeSaga } from "@stores/sagas/auditIntake/getAuditIntakeSaga/getAuditIntakeSaga";

export default function* rootSaga(): SagaIterator {
  yield all([takeLatest(requestCOI.toString(), fetchCOI)]);
  yield all([
    takeLatest(createInstantCoiAction.toString(), createInstantCOISaga)
  ]);
  yield all([
    takeLatest(
      accountActions.sendRegistrationEmailAction.toString(),
      sendRegistrationEmailSaga
    )
  ]);
  yield all([
    takeLatest(
      accountActions.getUserInfoByTokenAction.toString(),
      getUserInfoByTokenSaga
    )
  ]);
  yield all([
    takeLatest(
      accountActions.createPasswordAction.toString(),
      createPasswordSaga
    )
  ]);
  yield all([
    takeLatest(
      accountActions.forgotPasswordAction.toString(),
      forgotPasswordSaga
    )
  ]);
  yield all([
    takeLatest(
      paymentActions.getPolicyInfoAction.toString(),
      paymentSetupLandingSaga
    )
  ]);
  yield all([
    takeLatest(
      paymentActions.resendPaymentSetupEmail.toString(),
      resendPaymentSetupEmailSaga
    )
  ]);
  yield all([
    takeLatest(paymentActions.setCardPayment.toString(), cardPaymentSetupSaga)
  ]);
  yield all([
    takeLatest(paymentActions.setACHPayment.toString(), bankPaymentSetupSaga)
  ]);
  yield all([
    takeLatest(setInternalPayment.toString(), internalPaymentSetupSaga)
  ]);
  yield all([
    takeLatest(checkServiceHealth.toString(), serviceHealthCheckSaga)
  ]);
  yield all([
    takeLatest(authUserActions.setAuthUser, getTermsAndConditionsAcceptedSaga)
  ]);
  yield all([
    takeLatest(
      termsAndConditionsActions.acceptTermsAndConditions,
      acceptTermsAndConditionsSaga
    )
  ]);
  yield all([
    takeLatest(auditIntakeActions.getAuditIntake, getAuditIntakeSaga)
  ]);
}

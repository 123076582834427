import { topBarHeight, topBarZIndex } from "@pie/online-account-externals";
import { makeStyles, Theme } from "@material-ui/core";

export const useTopBarStyles = makeStyles(
  (theme: Theme) => ({
    topBar: {
      flexBasis: topBarHeight,
      padding: theme.spacing(0, 8),
      flexGrow: 0,
      right: 0,
      top: 0,
      width: "100%",
      background: theme.palette.background.paper,
      zIndex: topBarZIndex,
      boxShadow: `0 1px 7px 2px ${theme.palette.grey[400]}`,
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3, 2.5)
      }
    },
    topBarExitToApp: {
      paddingRight: theme.spacing(1)
    },
    link: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      paddingRight: theme.spacing(2),
      "& a": {
        color: theme.palette.text.primary,
        textDecoration: "none"
      },
      [theme.breakpoints.down("md")]: {
        paddingRight: 0
      }
    },
    logoContainer: {
      paddingTop: "5px"
    },
    image: {
      height: "100%",
      width: "220px",
      [theme.breakpoints.down("md")]: {
        width: "170px"
      }
    }
  }),
  { name: "TopBar" }
);

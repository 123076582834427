import React, { useEffect, useMemo } from "react";
import { Modal, useAuth } from "@pie/components";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsTncModalClosed,
  termsAndConditionsActions
} from "@stores/termsAndConditions";
import { Footer } from "./Footer/Footer";
import { Body } from "./Body/Body";
import {
  analyticsAction,
  AnalyticsEventBehavior
} from "@pie/online-account-externals";

export const TermsAndConditionsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const isTncModalClosed = useSelector(selectIsTncModalClosed);

  const isOpen = useMemo(
    () => isAuthenticated && !isTncModalClosed,
    [isAuthenticated, isTncModalClosed]
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(
        analyticsAction({
          actionName: "Terms and Conditions modal",
          type: AnalyticsEventBehavior.VIEW
        })
      );
    }
  }, [dispatch, isOpen]);

  return (
    <Modal
      modalTitle="Pie Insurance Terms and Conditions"
      open={isOpen}
      onClose={() => dispatch(termsAndConditionsActions.closeModal())}
      disableBackdropClick={true}
      footer={
        <Footer
          onAcceptClick={() =>
            dispatch(termsAndConditionsActions.acceptTermsAndConditions())
          }
          onDeclineClick={() =>
            dispatch(termsAndConditionsActions.denyTermsAndConditions())
          }
        />
      }
      verticalAlignment="center"
    >
      <Body />
    </Modal>
  );
};

import _ from "lodash";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Snack, SnackbarState } from "@pie/online-account-externals";
import { SnackbarKey } from "notistack";
import {
  enqueueSnackbar,
  removeSnackbar,
  closeSnackbar,
  CloseSnackbarOptions,
  addSuccessSnackbar,
  addErrorSnackbar
} from "@pie/online-account-externals";

export const snackIdPrefix = "snackId-";
export const snackbarKey = "snackbar";
export const initialState: SnackbarState = {
  snacks: []
};

let context = _.runInContext();
export const resetContext = (): void => {
  context = _.runInContext();
};

const addSnackbar = (state: SnackbarState, snack: Partial<Snack>) => {
  const key = snack.key || context.uniqueId(snackIdPrefix);
  return {
    snacks: [
      {
        message: snack.message,
        options: {
          ...snack.options
        },
        key: key
      },
      ...state.snacks
    ]
  };
};

export const snackbar = createSlice({
  name: snackbarKey,
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(enqueueSnackbar, (state, { payload }) =>
      addSnackbar(state, payload)
    );
    builder.addCase(addSuccessSnackbar, (state, { payload }) =>
      addSnackbar(state, {
        message: payload.message,
        options: {
          ...payload.options,
          variant: "success"
        }
      })
    );
    builder.addCase(addErrorSnackbar, (state, { payload }) =>
      addSnackbar(state, {
        message: payload.message,
        options: {
          ...payload.options,
          variant: "error"
        }
      })
    );
    builder.addCase(
      closeSnackbar,
      (state, { payload }: PayloadAction<CloseSnackbarOptions>) => ({
        snacks: state.snacks.map(snack =>
          payload.dismissAll || snack.key === payload.key
            ? { ...snack, dismissed: true }
            : { ...snack }
        )
      })
    );
    builder.addCase(
      removeSnackbar,
      (state, { payload }: PayloadAction<SnackbarKey>) => ({
        snacks: state.snacks.filter(snack => snack.key !== payload)
      })
    );
  }
});

export default snackbar.reducer;

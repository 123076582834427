import { PayloadAction } from "@reduxjs/toolkit";
import { createInstantCoiApi } from "@api/createInstantCoi";
import {
  call,
  CoiType,
  InstantCOIPayload
} from "@pie/online-account-externals";
import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { selectAuthUserIdToken } from "@pie/online-account-externals";
import { coiRequestFinished } from "@pie/online-account-externals";

export function* createInstantCOISaga(
  action: PayloadAction<InstantCOIPayload>
): SagaIterator {
  try {
    const idToken = yield select(selectAuthUserIdToken);
    const response = yield call(createInstantCoiApi, idToken, action.payload);

    if (response.ok) {
      const resData = yield response.json();
      return yield put(
        coiRequestFinished({
          success: true,
          documentUrl: resData.url,
          emailSuccess: resData.emailSuccessful,
          coiType: CoiType.INSTANT
        })
      );
    } else {
      return yield put(
        coiRequestFinished({
          success: false,
          coiType: CoiType.INSTANT
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
}

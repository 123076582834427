/** @jsxRuntime classic */
/** @jsx jsxCustomEvent */
import jsxCustomEvent from "@micro-zoe/micro-app/polyfill/jsx-custom-event";
import React from "react";
import { Loader } from "@pie/components";
import { useCallback, useState } from "react";
import { EventCenterForMicroApp } from "@micro-zoe/micro-app";

export type MicroAppInterface = {
  name: string;
  url: string;
  shadowDOM?: boolean;
  destroy?: boolean;
  inline?: boolean;
  disableScopecss?: boolean;
  disableSandbox?: boolean;
  macro?: boolean;
  onMounted?: () => void;
  onCreated?: () => void;
  onBeforemount?: () => void;
  onUnmount?: () => void;
  onError?: () => void;
  path?: string;
  data?: object;
  exactMatch?: boolean;
};

const MicroApp: React.FC<MicroAppInterface> = ({
  onMounted,
  onBeforemount,
  name,
  ...rest
}) => {
  const [showLoading, setLoading] = useState(true);

  const handleBeforeMount = useCallback(() => {
    (window as any)[name] = {
      eventCenter: new EventCenterForMicroApp(name),
      appName: name
    };
  }, [onBeforemount]);

  const handleMounted = useCallback(() => {
    setLoading(false);
    onMounted?.();
  }, [onMounted]);

  return (
    <div style={{ width: "100%" }}>
      {showLoading && <Loader />}
      <micro-app
        {...rest}
        name={name}
        onBeforemount={handleBeforeMount}
        onMounted={handleMounted}
        library={name}
      />
    </div>
  );
};

export default MicroApp;

import {
  AchDetailData,
  AuthUserState,
  PaymentState
} from "@pie/online-account-externals";
import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { ResendPaymentEmailRequestPayload } from "../../api/resendPaymentSetupEmail";
import { accountActions } from "../account";

export const initialState = {
  isIssued: false,
  hasError: false,
  errorMessage: undefined,
  nextStepMessage: undefined
} as PaymentState;

export type SetAuthUserAction = PayloadAction<AuthUserState>;
export const paymentSetupKey = "payment";

const payment = createSlice({
  name: paymentSetupKey,
  initialState,
  reducers: {
    paymentSetupLandingSuccessful: (state, action) => {
      return {
        ...state,
        hasError: false,
        errorMessage: undefined,
        ...action.payload
      };
    },
    emailTokenExpired: (state, action) => {
      const { data, isIssued } = action.payload;
      return {
        ...state,
        userEmailAddress: data,
        isIssued
      };
    },
    resendPaymentSetupEmailSuccess: state => state,
    resendPaymentSetupEmailFailed: state => state,
    setPaymentSuccessful: state => state,
    getUserInfoSuccessful: (state, action) => ({
      ...state,
      ...action.payload
    }),
    setPublicPaymentFailed: (state, action) => {
      state.hasError = true;
      state.errorMessage = action.payload.message || undefined;
      state.nextStepMessage = action.payload.nextStepMessage;
    },
    resetPaymentSetupError: state => {
      state.hasError = false;
      state.errorMessage = undefined;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      accountActions.saveEmailToken,
      (state, action): PaymentState => {
        return { ...state, emailToken: action.payload };
      }
    );
  }
});

export enum PaymentActionType {
  GET_POLICY_INFO = "payment/getPolicyInfo",
  GET_USER_INFO = "payment/getUserInfo",
  PAYMENT_SETUP_LANDING_FAILED = "payment/paymentSetupLandingFailed",
  RESEND_PAYMENT_SETUP_EMAIL = "payment/resendPaymentSetupEmail",
  SET_CARD_PAYMENT = "payment/setCardPayment",
  SET_BANK_PAYMENT = "payment/setBankPayment"
}

export const getPolicyInfoAction = createAction<{ isIssued: boolean }>(
  PaymentActionType.GET_POLICY_INFO
);
export const paymentSetupLandingFailed = createAction(
  PaymentActionType.PAYMENT_SETUP_LANDING_FAILED
);
export const resendPaymentSetupEmail = createAction<
  ResendPaymentEmailRequestPayload,
  PaymentActionType.RESEND_PAYMENT_SETUP_EMAIL
>(PaymentActionType.RESEND_PAYMENT_SETUP_EMAIL);

export const setCardPayment = createAction<string>(
  PaymentActionType.SET_CARD_PAYMENT
);

export const setACHPayment = createAction<AchDetailData>(
  PaymentActionType.SET_BANK_PAYMENT
);
export const getUserPaymentInfo = createAction<{ isIssued: boolean }>(
  PaymentActionType.GET_USER_INFO
);

export const paymentActions = {
  ...payment.actions,
  getPolicyInfoAction,
  paymentSetupLandingFailed,
  resendPaymentSetupEmail,
  setCardPayment,
  setACHPayment,
  getUserPaymentInfo
};

export default payment.reducer;

import { Link, Typography } from "@material-ui/core";
import React from "react";
import { ExternalLinks } from "@utils/constants";

export const agencyPartnerText = "Are you an agency partner?";

export const AgencyPartnerLink: React.FC = () => (
  <Typography color="textPrimary">
    <Link href={ExternalLinks.PARTNER_PORTAL_SIGN_IN} underline="always">
      {agencyPartnerText}
    </Link>
  </Typography>
);

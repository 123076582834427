import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { getAuthContext, useSettings } from "@pie/components";
import { useDispatch, useSelector, useStore } from "react-redux";
import { isEqual } from "lodash";
import queryString from "query-string";
import { Route, Switch, useLocation } from "react-router-dom";
import { useAppStyles } from "./styles";
import { Layout } from "../Layout";
import { Typography, useTheme } from "@material-ui/core";
import {
  analyticsAction,
  AnalyticsActionName,
  AuthRoutes,
  OnlineAccountWrapperSettings,
  selectAuthUser
} from "@pie/online-account-externals";
import {
  AppRoute,
  authenticatedRedirectRoute,
  authenticatedRoutes,
  publicRedirectRoute,
  publicRoutes
} from "@routes";
import { Notifier } from "../Notifier";
import { SnackbarOrigin, SnackbarProvider } from "notistack";
import { useSnackbarStyles } from "@components/commonStyles";
import { useHistory } from "react-router";
import { authUserActions } from "@stores/authUser";

import "./App.css";
import { healthCheckActions } from "@stores/healthCheck";
import { TermsAndConditionsModal } from "@components/TermsAndConditionsModal/TermsAndConditionsModal";
import { FeatureFlagService } from "@pie-insurance/core-networking-feature-flags-launchdarkly-client";
import { componentDestroyed } from "@stores/root/actions";

const defaultMaximumSnacks = 1;
const defaultAutoHideDuration = 3000; //3s
const defaultAnchorOrigin: SnackbarOrigin = {
  horizontal: "center",
  vertical: "top"
};

const renderRoutes = (routes: AppRoute[]) =>
  routes.map(({ key, ...route }) => (
    <Route key={key ?? route.path} {...route} />
  ));

const AppContent: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(componentDestroyed());
    };
  }, []);

  return <>{children}</>;
};

const App: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const store = useStore();
  const { search, pathname } = useLocation();
  const { isAuthenticated, user, loading } = useContext(getAuthContext());
  const reduxAuthUser = useSelector(selectAuthUser);
  const { healthCheckConfiguration, launchDarklyConfiguration } =
    useSettings<OnlineAccountWrapperSettings>();
  const [isAuditLive, setIsAuditLive] = useState<boolean | undefined>(
    undefined
  );

  useEffect(() => {
    async function getAuditFeatureFlag() {
      const clientId = launchDarklyConfiguration?.clientId;
      if (clientId) {
        const service = new FeatureFlagService(clientId);
        const auditFeatureFlag = await service.getFeatureFlag(
          "feature-online-account-audit-route"
        );
        setIsAuditLive(auditFeatureFlag.enabled);
      }
    }

    if (!isAuditLive) {
      getAuditFeatureFlag();
    }
  }, [launchDarklyConfiguration]);

  const dispatch = useDispatch();
  const initialQueryParams = useRef<string>(search);

  const startHealthCheck = useCallback(() => {
    if (!healthCheckConfiguration || !healthCheckConfiguration.delayInSeconds) {
      return;
    }

    dispatch(healthCheckActions.checkServiceHealth());
    setTimeout(
      startHealthCheck,
      healthCheckConfiguration.delayInSeconds * 1000
    );
  }, [healthCheckConfiguration]);

  useEffect(() => {
    startHealthCheck();
  }, [startHealthCheck]);

  useEffect(() => {
    if (user && !isEqual(reduxAuthUser.email, user.email)) {
      // shallow JS compare fails
      dispatch(authUserActions.setAuthUser(user));
    }

    // if we have a subject (used as the analytics distinctId), send the analytics event to link their landing queryparams to their session
    if (user?.subject && initialQueryParams.current) {
      // queryString.parse is initalized with Object.create(null) and lacks a prototype. This spread prevents a redux serializable data error
      const properties = { ...queryString.parse(initialQueryParams.current) };
      // eslint-disable-next-line no-prototype-builtins
      if (properties.hasOwnProperty("token")) delete properties.token;

      dispatch(
        analyticsAction({
          actionName: AnalyticsActionName.LAND_WITH_QUERYPARAMS,
          properties
        })
      );
      initialQueryParams.current = "";
    }
  }, [dispatch, user, reduxAuthUser, isAuthenticated]);

  const authRoutes = useMemo(() => {
    let authRoutes = authenticatedRoutes({ store, theme, history });
    if (!isAuditLive) {
      authRoutes = authRoutes.filter(r => r.path != AuthRoutes.AUDIT);
    }
    return authRoutes;
  }, [isAuditLive, store, theme, history]);

  const { root } = useAppStyles();
  const {
    root: snackRoot,
    success,
    error,
    info,
    warning
  } = useSnackbarStyles();

  return (
    <SnackbarProvider
      preventDuplicate
      maxSnack={defaultMaximumSnacks}
      anchorOrigin={defaultAnchorOrigin}
      autoHideDuration={defaultAutoHideDuration}
      classes={{
        root: snackRoot,
        variantSuccess: success,
        variantError: error,
        variantWarning: warning,
        variantInfo: info
      }}
    >
      <Notifier />
      <Typography component="div" className={root}>
        <AppContent>
          <Layout pathname={pathname}>
            <Switch>
              {renderRoutes(publicRoutes)}
              {isAuthenticated && renderRoutes(authRoutes)}
              {isAuthenticated && renderRoutes(authenticatedRedirectRoute)}
              {!loading &&
                !user &&
                !isAuthenticated &&
                renderRoutes(publicRedirectRoute)}
            </Switch>
          </Layout>
        </AppContent>
      </Typography>
      <TermsAndConditionsModal />
    </SnackbarProvider>
  );
};

export default App;

import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { bankSetupApi } from "@api/bankSetup";
import { paymentActions } from "../../paymentSetup";
import { PayloadAction } from "@reduxjs/toolkit";
import { loadingActions } from "../../loadingState";
import {
  AchDetailData,
  call,
  HttpStatusCodes,
  PublicRoutes,
  selectEmailToken,
  selectPayment
} from "@pie/online-account-externals";
import { push } from "connected-react-router";
import { nextStepMessage } from "@utils/helpers";

export function* bankPaymentSetupSaga(
  action: PayloadAction<AchDetailData>
): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true));
    const token = yield select(selectEmailToken);
    const { isIssued } = yield select(selectPayment);
    // @ts-ignore
    const res = yield call(bankSetupApi, {
      token,
      ownerData: JSON.stringify(action.payload)
    });

    if (res.ok) {
      yield put(loadingActions.setLoading(false));
      yield put(paymentActions.setPaymentSuccessful());
      return isIssued
        ? yield put(push(PublicRoutes.PAYMENT_CUSTOMER_SUCCESS))
        : yield put(push(PublicRoutes.PAYMENT_PROSPECT_SUCCESS));
    }

    if (res.status < HttpStatusCodes.InternalServerError) {
      const {
        errors: { errorMessage }
      } = yield res.json();
      yield put(loadingActions.setLoading(false));
      return yield put(
        paymentActions.setPublicPaymentFailed({
          message: errorMessage,
          nextStepMessage
        })
      );
    }

    throw res;
  } catch (error) {
    yield put(loadingActions.setLoading(false));
    return yield put(push(PublicRoutes.PAYMENT_TECH_ERROR));
  }
}

import {
  AnalyticsEvent,
  convertToAnalyticsEvent,
  OnlineAccountWrapperApplicationState
} from "@pie/online-account-externals";
import { paymentActions } from "../../paymentSetup";
import { PayloadAction } from "@reduxjs/toolkit";

export type PaymentActionTypes =
  `onlineaccountwrapperui/paymentSetup/${keyof typeof paymentActions}`;

export type PaymentAction = {
  type: PaymentActionTypes;
  payload: PayloadAction<any>;
};

export const PaymentSetupEventNames: {
  [K in Exclude<
    PaymentActionTypes,
    "onlineaccountwrapperui/paymentSetup/resetPaymentSetupError"
  >]: string;
} = {
  "onlineaccountwrapperui/paymentSetup/emailTokenExpired":
    "Payment Setup Email Token Expired on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/getPolicyInfoAction":
    "Fetching Policy Info on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/getUserInfoSuccessful":
    "User Info Received on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/getUserPaymentInfo":
    "Fetching Payment Info on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/paymentSetupLandingFailed":
    "Failed Payment Setup Init on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/paymentSetupLandingSuccessful":
    "Successful Init of Payment Setup on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/resendPaymentSetupEmail":
    "Resend Payment Setup Email on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/resendPaymentSetupEmailSuccess":
    "Successful Resend of Payment Setup Email on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/resendPaymentSetupEmailFailed":
    "Failed Resend of Payment Setup Email on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/setACHPayment":
    "Submit ACH Setup on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/setCardPayment":
    "Submit Card Setup on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/setPaymentSuccessful":
    "Payment Setup Complete on Public Payment Setup Page",
  "onlineaccountwrapperui/paymentSetup/setPublicPaymentFailed":
    "Failed Payment Setup on Public Payment Setup Page"
};

export type PaymentSetupEventsMap = {
  [K in keyof typeof PaymentSetupEventNames]: () => AnalyticsEvent;
};
export const genericEventMap: PaymentSetupEventsMap = Object.entries(
  PaymentSetupEventNames
).reduce(
  (eventsMap, [actionType, actionName]) => ({
    ...eventsMap,
    [actionType]: (
      _: PaymentAction,
      state: OnlineAccountWrapperApplicationState
    ) => convertToAnalyticsEvent(state, { actionName })
  }),
  {} as PaymentSetupEventsMap
);

export const paymentSetupEventsMap = {
  ...genericEventMap
};

import { put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { SagaIterator } from "redux-saga";
import { createPasswordApi } from "../../../api/createPassword";
import {
  PublicRoutes,
  enqueueSnackbar,
  call,
  HttpStatusCodes
} from "@pie/online-account-externals";
import { SnackbarStatus } from "../../../utils/constants";
import { AccountActionType, accountActions } from "../../account";
import { loadingActions } from "../../loadingState";

export type CreatePasswordPayload = {
  policyNumber: string;
  zipCode: string;
  password: string;
};
export interface CreatePasswordAction {
  payload: CreatePasswordPayload;
  type: AccountActionType.CREATE_PASSWORD;
}

export function* createPasswordSaga(
  action: CreatePasswordAction
): SagaIterator {
  yield put(loadingActions.setLoading(true));
  try {
    const res = yield call(createPasswordApi, action.payload);
    if (res.ok) {
      yield put(loadingActions.setLoading(false));
      yield put(accountActions.createAccountSuccessful());
      yield put(enqueueSnackbar(SnackbarStatus.accountCreatedSuccess));
      return yield put(push(PublicRoutes.SIGN_IN));
    }
    throw res;
  } catch (error) {
    yield put(loadingActions.setLoading(false));
    // @ts-ignore
    if (error.status === HttpStatusCodes.BadRequest) {
      yield put(enqueueSnackbar(SnackbarStatus.accountAlreadyExistError));
      return yield put(accountActions.createAccountFailed());
    }
    yield put(enqueueSnackbar(SnackbarStatus.error));
    return yield put(accountActions.createAccountFailed());
  }
}

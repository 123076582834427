import React from "react";
import { Typography, Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

interface LinkTextProps {
  to: string;
  prefixText?: string;
  className?: string;
}

export const LinkText: React.FC<LinkTextProps> = ({
  to,
  prefixText,
  className,
  children
}) => (
  <Typography color="textPrimary">
    {prefixText}
    <Link
      component={RouterLink}
      className={className}
      to={to}
      underline="always"
    >
      {children}
    </Link>
  </Typography>
);

import { makeStyles, Theme } from "@material-ui/core";

export const callNowStyles = makeStyles((theme: Theme) => ({
  paperStyle: {
    padding: theme.spacing(2.5),
    margin: `${theme.spacing(1.25)}px auto ${theme.spacing(1.25)}px`
  },
  buttonStyle: {
    background: theme.palette.primary.light
  },
  iconStyle: {
    color: theme.palette.primary.main
  },
  contentStyle: {
    paddingRight: theme.spacing(2.5)
  }
}));

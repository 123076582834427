import { makeStyles, Theme } from "@material-ui/core";

export const usePaymentSetupStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    padding: theme.spacing(6, 15),
    [theme.breakpoints.down("md")]: {
      padding: 0
    }
  },
  formStyle: {
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(8, 0, 10)
    }
  },
  radioButtonsStyle: {
    flexWrap: "wrap"
  },
  boldText: {
    fontWeight: theme.typography.fontWeightMedium
  }
}));

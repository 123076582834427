import React, { useState, useEffect } from "react";
import { useAuthStyles } from "../styles";
import {
  LoadingButton,
  ControlledTextInput,
  PasswordInput,
  Loader,
  useIsMobile
} from "@pie/components";
import { Grid, Typography } from "@material-ui/core";
import { AuthCardWrapper } from "../AuthCardWrapper";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { CreatePasswordPayload } from "../../../store/sagas/createPassword/createPasswordSaga";
import { FieldName } from "../../../utils/constants";
import { policyNumberLabel, zipCodeLabel } from "./SendRegistrationEmail";
import { emailInputLabel } from "../SignIn/SignIn";
import { accountActions, selectAccount } from "../../../store/account";
import { selectLoadingState } from "../../../store/loadingState";

const { POLICY_NUMBER, ZIP_CODE, EMAIL, PASSWORD } = FieldName;

export const createAccountFormTestId = "create-account-form";
export const passwordLabel = "Password";
export const createAccountButtonLabel = "Create account";

export const CreatePassword: React.FC = () => {
  const { linkText, button } = useAuthStyles();
  const loading = useSelector(selectLoadingState);
  const { zipCode, policyNumber, createPasswordEmail } =
    useSelector(selectAccount);

  const [passwordValue, setPasswordValue] = useState<string>("");
  const isMobile = useIsMobile();

  const { reset, ...formMethods } = useForm();

  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = queryString.parse(location.search) || "";

  useEffect(() => {
    if (policyNumber || zipCode || createPasswordEmail) {
      reset({ policyNumber, zipCode, createPasswordEmail });
    }
  }, [createPasswordEmail, passwordValue, policyNumber, reset, zipCode]);

  useEffect(() => {
    dispatch(accountActions.getUserInfoByTokenAction(token as string));
  }, [dispatch, token]);

  const handleFormSubmit = (
    data: CreatePasswordPayload,
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    e.preventDefault();
    dispatch(
      accountActions.createPasswordAction({
        password: passwordValue,
        policyNumber: data.policyNumber,
        zipCode: data.zipCode
      })
    );
  };

  const handlePassword = (e: any) => {
    const { value } = e.currentTarget;
    setPasswordValue(value);
  };

  return (
    <AuthCardWrapper>
      {createPasswordEmail || policyNumber || zipCode ? (
        <>
          <Typography paragraph variant="h2" color="primary">
            Create a password for your account.
          </Typography>
          <FormProvider {...{ ...formMethods, reset }}>
            <form
              onSubmit={formMethods.handleSubmit(handleFormSubmit as any)}
              data-testid={createAccountFormTestId}
            >
              <Grid container justify="space-between" alignItems="flex-start">
                <Grid item xs={12}>
                  <ControlledTextInput
                    required
                    id={EMAIL}
                    type="text"
                    label={emailInputLabel}
                    name={EMAIL}
                    value={createPasswordEmail}
                    disabled
                  />
                </Grid>
                <Grid container spacing={isMobile ? 0 : 2}>
                  <Grid item xs={12} sm={6}>
                    <ControlledTextInput
                      required
                      id={POLICY_NUMBER}
                      type="text"
                      label={policyNumberLabel}
                      name={POLICY_NUMBER}
                      value={policyNumber}
                      disabled={!!policyNumber}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ControlledTextInput
                      id={ZIP_CODE}
                      label={zipCodeLabel}
                      name={ZIP_CODE}
                      value={zipCode}
                      disabled={!!zipCode}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <PasswordInput
                    required
                    id={PASSWORD}
                    name={PASSWORD}
                    label={passwordLabel}
                    value={passwordValue}
                    onChange={handlePassword}
                  />
                </Grid>
              </Grid>
              <Grid
                className={linkText}
                container
                direction="column"
                alignItems="center"
              >
                <Grid item style={{ width: isMobile ? "100%" : "unset" }}>
                  <LoadingButton
                    loading={loading as boolean}
                    className={button}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!passwordValue}
                    fullWidth={isMobile}
                  >
                    {createAccountButtonLabel}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </>
      ) : (
        <Loader />
      )}
    </AuthCardWrapper>
  );
};

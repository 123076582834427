import { put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { SagaIterator } from "redux-saga";
import {
  PublicRoutes,
  enqueueSnackbar,
  call
} from "@pie/online-account-externals";
import { sendRegistrationEmailApi } from "../../../api/sendRegistrationEmail";
import { SnackbarStatus } from "../../../utils/constants";
import { loadingActions } from "../../loadingState";
import { AccountActionType, accountActions } from "../../account";

export interface RegistrationEmailPayload {
  policyNumber: string;
  zipCode: string;
  isExpiredResend?: boolean;
}

export interface SendRegistrationEmailAction {
  payload: RegistrationEmailPayload;
  type: AccountActionType.SEND_REGISTRATION_EMAIL;
}

export function* sendRegistrationEmailSaga(
  action: SendRegistrationEmailAction
): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true));
    const res = yield call(sendRegistrationEmailApi, action.payload);
    if (res.ok) {
      yield put(loadingActions.setLoading(false));
      yield put(accountActions.sendRegistrationEmailSuccessful(action.payload));
      yield put(enqueueSnackbar(SnackbarStatus.emailSendSuccess));
      return yield put(push(PublicRoutes.EMAIL_SEND_SUCCESS));
    } else if (res.status === 404) {
      yield put(loadingActions.setLoading(false));
      return yield put(accountActions.sendRegistrationEmailFailed());
    } else {
      yield put(loadingActions.setLoading(false));
      throw res;
    }
  } catch (error) {
    yield put(loadingActions.setLoading(false));
    yield put(accountActions.sendRegistrationEmailFailed());
    yield put(enqueueSnackbar(SnackbarStatus.error));
    return console.error(error);
  }
}

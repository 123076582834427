import { InstantCOIPayload } from "@pie/online-account-externals";
import { ApiPath, ApiRoute, fetchOptions } from "./constants";

export const createInstantCoiApi = (
  idToken: string,
  payload: InstantCOIPayload
): Promise<Response> =>
  fetch(`${ApiRoute.DOCUMENT_REQUEST}${ApiPath.INSTANT_COI}`, {
    ...fetchOptions,
    headers: {
      Authorization: `Bearer ${idToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest"
    },
    method: "POST",
    body: JSON.stringify(payload)
  });

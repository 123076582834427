import { makeStyles, Theme } from "@material-ui/core";

export const useSideMenuStyles = makeStyles((theme: Theme) => {
  const menuItemContent = {
    borderRadius: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    width: "100%"
  };

  return {
    footer: {
      "& p, span, a": {
        color: `${theme.palette.common.white} !important`
      },
      padding: theme.spacing(1, 2)
    },
    iconStyle: {
      color: theme.palette.common.white,
      width: "1.5625rem"
    },
    label: {
      color: theme.palette.common.white,
      marginLeft: "0.625rem",
      fontWeight: theme.typography.fontWeightRegular
    },
    menuContent: {
      backgroundColor: theme.palette.primary.main,
      height: "100%",
      padding: theme.spacing(2, 0)
    },
    menuItem: {
      "&:first-child": {
        marginBottom: "2rem"
      }
    },
    menuItemContent: {
      ...menuItemContent,
      padding: theme.spacing(1, 2)
    },
    menuItemContentSelected: {
      ...menuItemContent,
      padding: theme.spacing(1, 2, 1, 1.5)
    },
    menuItemSelected: {
      backgroundColor: theme.palette.primary.dark,
      borderLeft: `${theme.spacing(0.5)}px solid #BE6BAC`
    },
    signOutMenuItem: {
      display: "flex",
      alignItems: "center",
      flex: 1
    }
  };
});

import { SagaIterator } from "redux-saga";
import { put, select } from "redux-saga/effects";
import { cardSetupApi } from "@api/cardSetup";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  call,
  HttpStatusCodes,
  PublicRoutes,
  selectEmailToken,
  selectPayment
} from "@pie/online-account-externals";
import { push } from "connected-react-router";
import { loadingActions } from "../../loadingState";
import { paymentActions } from "../../paymentSetup";
import { stripeErrorDeclineCodes, nextStepMessage } from "@utils/helpers";

export function* cardPaymentSetupSaga(
  action: PayloadAction<string>
): SagaIterator {
  try {
    yield put(loadingActions.setLoading(true));
    const emailToken = yield select(selectEmailToken);
    const { isIssued } = yield select(selectPayment);
    // @ts-ignore
    const res = yield call(cardSetupApi, {
      stripeSourceToken: action.payload,
      emailToken
    });

    if (res.ok) {
      yield put(loadingActions.setLoading(false));
      yield put(paymentActions.setPaymentSuccessful());
      return isIssued
        ? yield put(push(PublicRoutes.PAYMENT_CUSTOMER_SUCCESS))
        : yield put(push(PublicRoutes.PAYMENT_PROSPECT_SUCCESS));
    }

    if (res.status < HttpStatusCodes.InternalServerError) {
      yield put(loadingActions.setLoading(false));
      const {
        errors: { errorDeclineCode, errorType }
      } = yield res.json();

      const responseDeclineCode = errorDeclineCode[0] || errorType[0];

      const mappedError = stripeErrorDeclineCodes.find(
        error => error.declineCode === responseDeclineCode
      );

      return yield put(
        paymentActions.setPublicPaymentFailed({
          message: mappedError?.description,
          nextStepMessage: mappedError?.nextStepMessage || nextStepMessage
        })
      );
    }

    throw res;
  } catch (error) {
    yield put(loadingActions.setLoading(false));
    return yield put(push(PublicRoutes.PAYMENT_TECH_ERROR));
  }
}

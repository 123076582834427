import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback
} from "react";
import {
  Grid,
  withStyles,
  Tab,
  Tabs,
  TabsProps,
  Typography
} from "@material-ui/core";
import {
  AlertWithIconAndClose,
  getAuthContext,
  Loader,
  useIsMobile
} from "@pie/components";
import { TabOption, temporaryServiceOutageMessage } from "@utils/constants";
import { TopBar } from "@components/TopBar";
import { tabStyles, useLayoutStyles } from "./styles";
import { ResourcesCard } from "../ResourcesCard";
import { ContactCard } from "../ContactCard";
import { useHistory } from "react-router-dom";
import { AuthRoutes, PublicRoutes } from "@pie/online-account-externals";
import { Footer } from "@components/Footer";
import { SideMenu } from "@components/SideMenu/SideMenu";
import { useSelector } from "react-redux";
import { selectDegradedServiceStatus } from "@stores/healthCheck";
import { ReportAccidentCard } from "@components/ReportAccidentCard/ReportAccidentCard";
import { selectIsTnCLoading } from "@stores/termsAndConditions";
import { SignOutPrompt } from "@components/SignOutPrompt/SignOutPrompt";

const StyledTabs = withStyles(tabStyles)((props: TabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

const authRoutes = Object.values(AuthRoutes);

export const tabNames = [
  { label: TabOption.HOME, value: AuthRoutes.HOME },
  { label: TabOption.POLICY, value: AuthRoutes.POLICY },
  { label: TabOption.PAYMENTS, value: AuthRoutes.PAYMENTS },
  { label: TabOption.DOCUMENTS, value: AuthRoutes.DOCUMENTS }
];

export interface LayoutProps {
  header?: string;
  pathname: string;
}

export const Layout: React.FC<LayoutProps> = ({ pathname, children }) => {
  const pathnameRef = useRef<string | null>(null);
  const childWrapperRef = useRef<HTMLDivElement | null>(null);
  const [tab, setTab] = useState<string>(AuthRoutes.HOME);
  const history = useHistory();
  const { isAuthenticated } = useContext(getAuthContext());
  const isMobile = useIsMobile();
  const hasDegradedServices = useSelector(selectDegradedServiceStatus);
  const isTnCLoading = useSelector(selectIsTnCLoading);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: AuthRoutes
  ) => {
    setTab(newValue);
    history.push(newValue);
  };

  const isAuthRoute = useMemo(
    () => authRoutes.some(authRoute => authRoute.includes(pathname)),
    [pathname]
  );

  const isNotAudit = pathname !== AuthRoutes.AUDIT;

  const showTabs = useMemo(
    () =>
      !isMobile &&
      isAuthenticated &&
      tabNames.some(tab => tab.value === pathname),
    [isAuthenticated, isMobile, pathname]
  );

  const showResourceCards = isAuthenticated && isAuthRoute && isNotAudit;

  const showServiceAlert = useMemo(
    () => hasDegradedServices && isAuthRoute,
    [hasDegradedServices, isAuthRoute]
  );

  const ServiceAlert = useCallback(
    () =>
      showServiceAlert ? (
        <Grid item>
          <AlertWithIconAndClose type="secondary" className={serviceAlert}>
            <Typography>{temporaryServiceOutageMessage}</Typography>
          </AlertWithIconAndClose>
        </Grid>
      ) : null,
    [showServiceAlert]
  );

  useEffect(() => {
    if (pathname !== pathnameRef.current) {
      pathnameRef.current = pathname;
      if (childWrapperRef.current !== null) {
        childWrapperRef.current.scrollTop = 0;
      }
    }
    if (
      pathname !== PublicRoutes.SIGN_IN &&
      tabNames.map(tab => tab.value.valueOf()).includes(pathname)
    ) {
      setTab(pathname);
    }
  }, [pathname]);

  const {
    appContent,
    tabs,
    tabContent,
    cardContainer,
    headerContainer,
    childContent,
    childStyles,
    serviceAlert
  } = useLayoutStyles();

  const renderTabs = tabNames.map(tab => {
    return <Tab key={tab.label} value={tab.value} label={tab.label} />;
  });

  return (
    <>
      <SideMenu />
      <Grid container direction="column" className={headerContainer}>
        <TopBar />
        {showTabs && (
          <StyledTabs
            value={tab}
            textColor="primary"
            onChange={handleChange as any}
            className={tabs}
          >
            {renderTabs}
          </StyledTabs>
        )}
      </Grid>
      <Grid
        className={appContent}
        container
        direction="column"
        justify="space-evenly"
        wrap="nowrap"
      >
        <Grid
          item
          container
          xs={12}
          ref={childWrapperRef}
          className={tabContent}
        >
          <Grid item container spacing={3}>
            <Grid item container xs className={childStyles}>
              <ServiceAlert />
              <Grid item className={childContent}>
                {isTnCLoading ? <Loader /> : children}
              </Grid>
            </Grid>
            {showResourceCards && (
              <Grid item xs={12} md={3} className={cardContainer}>
                <ContactCard />
                <ResourcesCard />
                <ReportAccidentCard />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item container>
          <Footer />
        </Grid>
      </Grid>
      <SignOutPrompt />
    </>
  );
};

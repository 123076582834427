import { makeStyles, Theme } from "@material-ui/core";

export const useFooterStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.up("xs")]: {
      justifyContent: "right"
    },
    [theme.breakpoints.down("xs")]: {
      "& > div, & button": {
        width: "100%"
      }
    }
  }
}));
